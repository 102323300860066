import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/css/effect-cube";
import "swiper/css/pagination";
import { Autoplay, EffectCube, Pagination } from "swiper";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "i18next";
import { getBankAuditEnabled } from "utils/envHelpers";
import { ROUTE_NAME } from "utils/constants/routes";
import CustomSelect from "components/custom-select/CustomSelect";
import useWindowSize from "utils/hooks/useWindowSize";
import { useURLMatch } from "utils/hooks/useURLMatch";
import IMG_LOGO from "assets/images/logo/logo-w-text.png";
import ICON_BURGER from "assets/icons/burger/hamburger-menu.svg";
import ICON_CLOSE from "assets/icons/burger/burger-close.svg";
import "./Header.scss";

const Header = () => {
  const { isScreenSize } = useWindowSize();
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const isURLMatch = useURLMatch(ROUTE_NAME.checkout);
  const { t } = useTranslation();

  const handleSwitchLanguage = (language) => {
    changeLanguage(language.toUpperCase());
  };

  const handleScroll = () => {
    window.scrollTo(0, 0);
  };

  const onLinkClick = () => {
    handleScroll();
    setOpenMobileMenu(false);
  };

  if (isURLMatch) {
    return;
  }

  return (
    <>
      <div className="HeaderBackground" />
      <div className={`Header ${openMobileMenu ? "mobileMenuOpen" : ""}`}>
        <div className="SectionContainer HeaderContentWrapper">
          <div className="HeaderLogoWrapper" onClick={handleScroll}>
            <Swiper
              effect={"cube"}
              grabCursor={false}
              cubeEffect={{
                shadow: false,
                slideShadows: false,
              }}
              pagination={false}
              modules={[EffectCube, Autoplay, Pagination]}
              className="HeaderLogoSwiper"
              loop={true}
              speed={1000}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
            >
              <SwiperSlide>
                <Link to="/">
                  <img src={IMG_LOGO} alt="logo" className="HeaderLogo" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link to="/">
                  <img src={IMG_LOGO} alt="logo" className="HeaderLogo" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link to="/">
                  <img src={IMG_LOGO} alt="logo" className="HeaderLogo" />
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link to="/">
                  <img src={IMG_LOGO} alt="logo" className="HeaderLogo" />
                </Link>
              </SwiperSlide>
            </Swiper>
          </div>
          <nav>
            {!getBankAuditEnabled() && (
              <ul
                className={`HeaderNav ${
                  openMobileMenu ? "mobileMenuOpen" : ""
                }`}
              >
                {isScreenSize("tablet") && (
                  <li className="LanguageAndCurrency">
                    <div>
                      <CustomSelect
                        type="language"
                        onSwitchLanguage={handleSwitchLanguage}
                      />
                    </div>
                    <div>
                      <CustomSelect type="currency" />
                    </div>
                  </li>
                )}
                <div>
                  <li className="HeaderNavLink">
                    <Link to="/" onClick={onLinkClick}>
                      {t("pages.home.name")}
                    </Link>
                  </li>
                  <li className="HeaderNavLink">
                    <Link to={ROUTE_NAME.shop} onClick={onLinkClick}>
                      {t("pages.shop.name")}
                    </Link>
                  </li>
                </div>
              </ul>
            )}
            <Link to={ROUTE_NAME.shop} className="shopNow">
              {t("buttons.shopNow")}
            </Link>
            {!isScreenSize("tablet") && (
              <div className="LanguageAndCurrency">
                <CustomSelect
                  type="language"
                  onSwitchLanguage={handleSwitchLanguage}
                />
                <CustomSelect type="currency" />
              </div>
            )}
          </nav>
          <button
            className="HeaderMenuButton"
            onClick={() => setOpenMobileMenu(!openMobileMenu)}
          >
            <img src={openMobileMenu ? ICON_CLOSE : ICON_BURGER} alt="" />
          </button>
        </div>
      </div>
    </>
  );
};

export default Header;
