import React from "react";
import { useTranslation } from "react-i18next";
import { METHODS } from "utils/constants/data/products";
import ICON_ARROW_TOP_RIGHT from "assets/icons/arrows/arrow-top-right.svg";
import "./ShippingPaymentMethods.scss";

const ShippingPaymentMethods = () => {
  const { t } = useTranslation();

  return (
    <section className=" ShippingPaymentMethods">
      <div className="SectionContainer">
        <h2 className="title">{t("components.shippingPaymentMethods.name")}</h2>
        <img src={ICON_ARROW_TOP_RIGHT} className="ArrowTopRight" />
        <p className="description">
          {t("components.shippingPaymentMethods.description")}
        </p>
        <div className="Methods">
          <div className="Shipping">
            {METHODS.shippingMethods.map((method, index) => {
              return (
                <div className="Method" key={index}>
                  <div className="MethodIcon">
                    <img src={method.imgSrc} />
                  </div>
                  <div>
                    <h3>{t(method.title)}</h3>
                    <p>{t(method.info)}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="Payment">
            {METHODS.paymentMethods.map((method, index) => {
              return (
                <div className="Method" key={index}>
                  <div className="MethodIcon">
                    <img src={method.imgSrc} />
                  </div>
                  <div>
                    <h3>{t(method.title)}</h3>
                    <p>{t(method.info)}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ShippingPaymentMethods;
