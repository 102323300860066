export const ACTION_NAMES = {
  instagram: "INSTAGRAM",
  whatsapp: "WHATSAPP",
  email: "EMAIL",
  mobile: "MOBILE",
  map: "MAP",
};

export const PHONE = {
  emptyPhoneValue: "+___ __ ___ __ __",
  emptyPhoneValueWithDialCode: "+994 __ ___ __ __",
  dialCode: "+994",
};

export const STEP_NAMES = {
  shipping: "shipping",
  information: "information",
  payment: "payment",
};
