import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "pages/checkout/components/checkout-steps/sub-components/modal/Modal";
import IMAGE_ERROR from "assets/images/payment/payment-error.svg";
import "./PaymentError.scss";

const PaymentError = () => {
  const { t } = useTranslation();

  return (
    <Modal buttonName={t("buttons.tryAgain")} handleClick={() => {}}>
      <div className="PaymentErrorModal">
        <div className="PaymentModalImage">
          <img src={IMAGE_ERROR} alt="error" />
        </div>
        <div className="PaymentModalContent">
          <h5 className="PaymentModalContentTitle">
            {t("pages.checkout.ohNo")} <br /> {t("pages.checkout.wentWrong")}
          </h5>
          <h6 className="PaymentModalContentSubTitle">
            {t("pages.checkout.paymentErrorInfo")}
            <br />
            {t("pages.checkout.tryAgain")}
          </h6>
        </div>
      </div>
    </Modal>
  );
};

export default PaymentError;
