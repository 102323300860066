import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updatePayment } from "redux/slices/checkoutStore";
import { createOrder } from "utils/api/services/general";
import useAsync from "utils/hooks/useAsync";
import { ReactComponent as IconArrowLeft } from "assets/icons/arrows/arrow-left-gray.svg";
import "./Payment.scss";

const Payment = ({ onGoBack }) => {
  const { execute, result, error } = useAsync(createOrder);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const basketData = useSelector((state) => state.basket);
  const clientData = useSelector((state) => state.checkout.clientData);
  const shippingData = useSelector((state) => state.checkout.shippingData);
  const payment = useSelector((state) => state.checkout.payment);
  const totalPrice = useSelector((state) => state.basket.total);
  const promo = useSelector((state) => state.basket.promoCode);

  const handleRadioChange = (e) => {
    dispatch(updatePayment(e.target.value));
  };

  const handlePayment = () => {
    const data =
      shippingData.method === "delivery"
        ? {
            basketData,
            clientData,
            shippingData,
            payment,
          }
        : {
            basketData,
            clientData,
            payment,
            shippingData: { method: shippingData.method },
          };

    execute(data);

    if (error) {
      console.error("Error creating order:", error);
    }
  };

  useEffect(() => {
    if (result?.bankData?.status === "00") {
      const url = result.bankData.url;
      window.open(url, "_blank");
    }
  }, [result]);

  return (
    <div className="CheckoutStep Payment">
      <h3>{t("pages.checkout.details")}</h3>
      <p className="SubTitle">
        {t("pages.checkout.detailsSubtext")}
        <span>
          {clientData.name.toUpperCase()} {clientData.lastName.toUpperCase()}
        </span>
      </p>
      <div className="DetailsTable">
        <div className="Detail">
          <div className="Title">
            <p>{t("pages.checkout.contact")}</p>
          </div>
          <div className="Info">{clientData.phone}</div>
        </div>
        {shippingData.method === "delivery" && (
          <div className="Detail">
            <div className="Title">
              <p>{t("pages.checkout.shipTo")}</p>
            </div>
            <div className="Info">{shippingData.addressLine1}</div>
          </div>
        )}
        <div className="Detail">
          <div className="Title">
            <p>{t("pages.checkout.method")}</p>
          </div>
          <div className="Info">
            {t(`pages.checkout.inputs.${shippingData.method}`)}
          </div>
        </div>
      </div>

      <h3>{t("pages.checkout.payment")}</h3>
      <p className="SubTitle">{t("pages.checkout.paymentSubtext")}</p>

      <form className="PaymentMethodSelection">
        <div className="CardMethod">
          <input
            type="radio"
            name="payment"
            id="card"
            value="card"
            checked={payment.method === "card"}
            onChange={handleRadioChange}
          />
          <label htmlFor="card">
            <div>
              <p className="Method">{t("pages.checkout.inputs.card")}</p>
              <p className="Info">{t("pages.checkout.atBank")}</p>
            </div>
            <div>
              <p className="Price">${totalPrice}</p>
              {promo && (
                <p className="Info">
                  {promo?.rate} %{t("components.basket.discount")}
                </p>
              )}
            </div>
          </label>
        </div>
        <div className="CashMethod">
          <input
            type="radio"
            name="payment"
            id="cash"
            value="cash"
            checked={payment.method === "cash"}
            onChange={handleRadioChange}
          />
          <label htmlFor="cash">
            <div>
              <p className="Method">{t("pages.checkout.inputs.cash")}</p>
              <p className="Info">{t("pages.checkout.atAddress")}</p>
            </div>
            <div>
              <p className="Price">${totalPrice}</p>
            </div>
          </label>
        </div>
      </form>

      <div className="CheckoutButtons">
        <button className="ReturnButton" onClick={onGoBack}>
          <IconArrowLeft /> {t("buttons.returnBack")}
        </button>
        <button className="ContinueButton" onClick={handlePayment}>
          {t("buttons.continue")}
        </button>
      </div>
    </div>
  );
};

export default Payment;

Payment.propTypes = {
  onNext: PropTypes.func,
  onGoBack: PropTypes.func.isRequired,
};
