import { PRODUCTS } from "utils/constants/data/products";

import IMG_GIA_ENERGY from "assets/images/products/buy-products-carousel/energy.png";
import IMG_GIA_HAIR_AND_NAILS from "assets/images/products/buy-products-carousel/hair.png";
import IMG_GIA_SLEEP from "assets/images/products/buy-products-carousel/sleep.png";
import IMG_GIA_KIDS from "assets/images/products/buy-products-carousel/kids.png";

import IMG_INFO_SLEEP from "assets/images/products-info/info-sleep.png";
import IMG_INFO_HAIR_NAIL from "assets/images/products-info/info-hair-nail.png";
import IMG_INFO_KIDS from "assets/images/products-info/info-kids.png";
import IMG_INFO_ENERGY from "assets/images/products-info/info-energy.png";

import IMG_PRODUCT_DESCRIPTION_SLEEP_1 from "assets/images/products-info/sleep-description.jpg";
import IMG_PRODUCT_DESCRIPTION_SLEEP_2 from "assets/images/products-info/sleep-description2.jpeg";
import IMG_PRODUCT_DESCRIPTION_HAIR_NAIL_1 from "assets/images/products-info/hair-nail-description1.jpg";
import IMG_PRODUCT_DESCRIPTION_HAIR_NAIL_2 from "assets/images/products-info/hair-nail-description2.jpg";
import IMG_PRODUCT_DESCRIPTION_KIDS_1 from "assets/images/products-info/kids-description.jpg";
import IMG_PRODUCT_DESCRIPTION_KIDS_2 from "assets/images/products-info/kids-description2.jpg";
import IMG_PRODUCT_DESCRIPTION_ENERGY_1 from "assets/images/products-info/energy-description.jpg";
import IMG_PRODUCT_DESCRIPTION_ENERGY_2 from "assets/images/products-info/energy-description2.jpeg";

import IMG_PRODUCT_DESIGN_SLEEP from "assets/images/products-info/sleep-design.png";
import IMG_PRODUCT_DESIGN_HAIR_NAIL from "assets/images/products-info/hair-nail-design.png";
import IMG_PRODUCT_DESIGN_KIDS from "assets/images/products-info/kids-design.png";
import IMG_PRODUCT_DESIGN_ENERGY from "assets/images/products-info/energy-design.png";

import IMG_BENEFITS_SLEEP_1 from "assets/images/products-info/sleep-benefits1.jpg";
import IMG_BENEFITS_SLEEP_2 from "assets/images/products-info/sleep-benefits2.jpg";
import IMG_BENEFITS_SLEEP_3 from "assets/images/products-info/sleep-benefits3.jpg";

import IMG_BENEFITS_KIDS1 from "assets/images/products-info/kids-benefits1.jpg";
import IMG_BENEFITS_KIDS2 from "assets/images/products-info/kids-benefits2.jpg";
import IMG_BENEFITS_KIDS3 from "assets/images/products-info/kids-benefits3.jpg";

import IMG_BENEFITS_HAIR_NAIL_1 from "assets/images/products-info/hair-benefits1.jpg";
import IMG_BENEFITS_HAIR_NAIL_2 from "assets/images/products-info/nail-benefits.jpg";
import IMG_BENEFITS_HAIR_NAIL_3 from "assets/images/products-info/hair-benefits2.jpg";

import IMG_BENEFITS_ENERGY_1 from "assets/images/products-info/energy-benefits1.jpg";
import IMG_BENEFITS_ENERGY_2 from "assets/images/products-info/energy-benefits2.jpg";
import IMG_BENEFITS_ENERGY_3 from "assets/images/products-info/energy-benefits3.jpg";

export const PRODUCT_TYPE = {
  sleep: "Sleep",
  hair: "Hair",
  kids: "Kids",
  energy: "Energy",
};
export const PRODUCT_INFO_LIST = [
  {
    id: PRODUCTS.sleep.id,
    productType: PRODUCT_TYPE.sleep,
    name: "pages.shop.productInfo.GiaSleep.name",
    imgSrc: IMG_GIA_SLEEP,
    price: "18.00",
    type: "gia vitamins",
    subTitle: "pages.shop.productInfo.GiaSleep.subTitle",
    infoImgSrc: IMG_INFO_SLEEP,
    description1: {
      imgSrc: IMG_PRODUCT_DESCRIPTION_SLEEP_1,
      content: "pages.shop.productInfo.GiaSleep.description.part1",
    },
    description2: {
      imgSrc: IMG_PRODUCT_DESCRIPTION_SLEEP_2,
      content: "pages.shop.productInfo.GiaSleep.description.part2",
    },
    benefits: [
      {
        imgSrc: IMG_BENEFITS_SLEEP_1,
        title: "pages.shop.productInfo.GiaSleep.benefits.benefit1.title",
      },
      {
        imgSrc: IMG_BENEFITS_SLEEP_2,
        title: "pages.shop.productInfo.GiaSleep.benefits.benefit2.title",
      },
      {
        imgSrc: IMG_BENEFITS_SLEEP_3,
        title: "pages.shop.productInfo.GiaSleep.benefits.benefit3.title",
      },
    ],
    productImgDesign: IMG_PRODUCT_DESIGN_SLEEP,
    Ingredients: {
      contains: [
        {
          name: "pages.shop.productInfo.ingredients.calories",
          value: 12.5,
        },
        {
          name: "pages.shop.productInfo.ingredients.totalCarbohydrate",
          value: "3.5 g",
        },
        {
          name: "pages.shop.productInfo.ingredients.totalSugars",
          value: "2 g",
        },
        {
          name: "pages.shop.productInfo.ingredients.vitaminD",
          value: "25 mcg",
        },
        {
          name: "pages.shop.productInfo.ingredients.magnesium",
          value: "25 mg",
        },
        {
          name: "pages.shop.productInfo.ingredients.sodium",
          value: "7.5 mg",
        },
        {
          name: "pages.shop.productInfo.ingredients.lemonBalmExtract",
          value: "45 mg",
        },
        {
          name: "pages.shop.productInfo.ingredients.herbalBlend",
          value: "20 mg",
          details: [
            "pages.shop.productInfo.GiaSleep.herbalBlendDetails.tart",
            "pages.shop.productInfo.GiaSleep.herbalBlendDetails.chamomile",
            "pages.shop.productInfo.GiaSleep.herbalBlendDetails.passion",
          ],
        },
        {
          name: "pages.shop.productInfo.ingredients.melatonin",
          value: "2.5 mg",
        },
      ],
      otherIngredients: "pages.shop.productInfo.GiaSleep.otherIngredients",
      additionalInfo: [
        "pages.shop.productInfo.GiaSleep.additionalInfo.suggestedUse",
        "pages.shop.productInfo.GiaSleep.additionalInfo.storage",
        "pages.shop.productInfo.GiaSleep.additionalInfo.riskAndSideEffects",
      ],
    },
  },
  {
    id: PRODUCTS.hairNail.id,
    name: "pages.shop.productInfo.GiaHairNail.name",
    imgSrc: IMG_GIA_HAIR_AND_NAILS,
    price: "30.00",
    type: "gia vitamins",
    subTitle: "pages.shop.productInfo.GiaHairNail.subTitle",
    infoImgSrc: IMG_INFO_HAIR_NAIL,
    productType: PRODUCT_TYPE.hair,
    description1: {
      imgSrc: IMG_PRODUCT_DESCRIPTION_HAIR_NAIL_1,
      content: "pages.shop.productInfo.GiaHairNail.description.part1",
    },
    description2: {
      imgSrc: IMG_PRODUCT_DESCRIPTION_HAIR_NAIL_2,
      content: "pages.shop.productInfo.GiaHairNail.description.part2",
    },
    benefits: [
      {
        imgSrc: IMG_BENEFITS_HAIR_NAIL_1,
        title: "pages.shop.productInfo.GiaHairNail.benefits.benefit1.title",
      },
      {
        imgSrc: IMG_BENEFITS_HAIR_NAIL_2,
        title: "pages.shop.productInfo.GiaHairNail.benefits.benefit2.title",
      },
      {
        imgSrc: IMG_BENEFITS_HAIR_NAIL_3,
        title: "pages.shop.productInfo.GiaHairNail.benefits.benefit3.title",
      },
    ],
    productImgDesign: IMG_PRODUCT_DESIGN_HAIR_NAIL,
    Ingredients: {
      contains: [
        {
          name: "pages.shop.productInfo.ingredients.calories",
          value: 12.5,
        },
        {
          name: "pages.shop.productInfo.ingredients.totalCarbohydrate",
          value: "3.5 g",
        },
        {
          name: "pages.shop.productInfo.ingredients.totalSugars",
          value: "2 g",
        },
        {
          name: "pages.shop.productInfo.ingredients.vitaminD",
          value: "25 mcg",
        },
        {
          name: "pages.shop.productInfo.ingredients.magnesium",
          value: "25 mg",
        },
        {
          name: "pages.shop.productInfo.ingredients.sodium",
          value: "7.5 mg",
        },
        {
          name: "pages.shop.productInfo.ingredients.lemonBalmExtract",
          value: "45 mg",
        },
        {
          name: "pages.shop.productInfo.ingredients.herbalBlend",
          value: "20 mg",
          details: [
            "pages.shop.productInfo.GiaHairNail.herbalBlendDetails.tart",
            "pages.shop.productInfo.GiaHairNail.herbalBlendDetails.chamomile",
            "pages.shop.productInfo.GiaHairNail.herbalBlendDetails.passion",
          ],
        },
        {
          name: "pages.shop.productInfo.ingredients.melatonin",
          value: "2.5 mg",
        },
      ],
      otherIngredients: "pages.shop.productInfo.GiaHairNail.otherIngredients",
      additionalInfo: [
        "pages.shop.productInfo.GiaHairNail.additionalInfo.suggestedUse",
        "pages.shop.productInfo.GiaHairNail.additionalInfo.storage",
        "pages.shop.productInfo.GiaHairNail.additionalInfo.riskAndSideEffects",
      ],
    },
  },
  {
    id: PRODUCTS.kids.id,
    name: "pages.shop.productInfo.GiaKids.name",
    imgSrc: IMG_GIA_KIDS,
    price: "18.00",
    type: "gia vitamins",
    subTitle: "pages.shop.productInfo.GiaKids.subTitle",
    infoImgSrc: IMG_INFO_KIDS,
    productType: PRODUCT_TYPE.kids,
    description1: {
      imgSrc: IMG_PRODUCT_DESCRIPTION_KIDS_1,
      content: "pages.shop.productInfo.GiaKids.description.part1",
    },
    description2: {
      imgSrc: IMG_PRODUCT_DESCRIPTION_KIDS_2,
      content: "pages.shop.productInfo.GiaKids.description.part2",
    },
    benefits: [
      {
        imgSrc: IMG_BENEFITS_KIDS1,
        title: "pages.shop.productInfo.GiaKids.benefits.benefit1.title",
      },
      {
        imgSrc: IMG_BENEFITS_KIDS2,
        title: "pages.shop.productInfo.GiaKids.benefits.benefit2.title",
      },
      {
        imgSrc: IMG_BENEFITS_KIDS3,
        title: "pages.shop.productInfo.GiaKids.benefits.benefit3.title",
      },
    ],
    productImgDesign: IMG_PRODUCT_DESIGN_KIDS,
    Ingredients: {
      contains: [
        {
          name: "pages.shop.productInfo.ingredients.calories",
          value: 12.5,
        },
        {
          name: "pages.shop.productInfo.ingredients.totalCarbohydrate",
          value: "3.5 g",
        },
        {
          name: "pages.shop.productInfo.ingredients.totalSugars",
          value: "2 g",
        },
        {
          name: "pages.shop.productInfo.ingredients.vitaminD",
          value: "25 mcg",
        },
        {
          name: "pages.shop.productInfo.ingredients.magnesium",
          value: "25 mg",
        },
        {
          name: "pages.shop.productInfo.ingredients.sodium",
          value: "7.5 mg",
        },
        {
          name: "pages.shop.productInfo.ingredients.lemonBalmExtract",
          value: "45 mg",
        },
        {
          name: "pages.shop.productInfo.ingredients.herbalBlend",
          value: "20 mg",
          details: [
            "pages.shop.productInfo.GiaKids.herbalBlendDetails.tart",
            "pages.shop.productInfo.GiaKids.herbalBlendDetails.chamomile",
            "pages.shop.productInfo.GiaKids.herbalBlendDetails.passion",
          ],
        },
        {
          name: "pages.shop.productInfo.ingredients.melatonin",
          value: "2.5 mg",
        },
      ],
      otherIngredients: "pages.shop.productInfo.GiaKids.otherIngredients",
      additionalInfo: [
        "pages.shop.productInfo.GiaKids.additionalInfo.suggestedUse",
        "pages.shop.productInfo.GiaKids.additionalInfo.storage",
        "pages.shop.productInfo.GiaKids.additionalInfo.riskAndSideEffects",
      ],
    },
  },
  {
    id: PRODUCTS.energy.id,
    name: "pages.shop.productInfo.GiaEnergy.name",
    imgSrc: IMG_GIA_ENERGY,
    price: "25.00",
    type: "gia vitamins",
    subTitle: "pages.shop.productInfo.GiaEnergy.subTitle",
    infoImgSrc: IMG_INFO_ENERGY,
    productType: PRODUCT_TYPE.energy,
    description1: {
      imgSrc: IMG_PRODUCT_DESCRIPTION_ENERGY_1,
      content: "pages.shop.productInfo.GiaEnergy.description.part1",
    },
    description2: {
      imgSrc: IMG_PRODUCT_DESCRIPTION_ENERGY_2,
      content: "pages.shop.productInfo.GiaEnergy.description.part2",
    },
    benefits: [
      {
        imgSrc: IMG_BENEFITS_ENERGY_1,
        title: "pages.shop.productInfo.GiaEnergy.benefits.benefit1.title",
      },
      {
        imgSrc: IMG_BENEFITS_ENERGY_2,
        title: "pages.shop.productInfo.GiaEnergy.benefits.benefit2.title",
      },
      {
        imgSrc: IMG_BENEFITS_ENERGY_3,
        title: "pages.shop.productInfo.GiaEnergy.benefits.benefit3.title",
      },
    ],
    productImgDesign: IMG_PRODUCT_DESIGN_ENERGY,
    Ingredients: {
      contains: [
        {
          name: "pages.shop.productInfo.ingredients.calories",
          value: 12.5,
        },
        {
          name: "pages.shop.productInfo.ingredients.totalCarbohydrate",
          value: "3.5 g",
        },
        {
          name: "pages.shop.productInfo.ingredients.totalSugars",
          value: "2 g",
        },
        {
          name: "pages.shop.productInfo.ingredients.vitaminD",
          value: "25 mcg",
        },
        {
          name: "pages.shop.productInfo.ingredients.magnesium",
          value: "25 mg",
        },
        {
          name: "pages.shop.productInfo.ingredients.sodium",
          value: "7.5 mg",
        },
        {
          name: "pages.shop.productInfo.ingredients.lemonBalmExtract",
          value: "45 mg",
        },
        {
          name: "pages.shop.productInfo.ingredients.herbalBlend",
          value: "20 mg",
          details: [
            "pages.shop.productInfo.GiaEnergy.herbalBlendDetails.tart",
            "pages.shop.productInfo.GiaEnergy.herbalBlendDetails.chamomile",
            "pages.shop.productInfo.GiaEnergy.herbalBlendDetails.passion",
          ],
        },
        {
          name: "pages.shop.productInfo.ingredients.melatonin",
          value: "2.5 mg",
        },
      ],
      otherIngredients: "pages.shop.productInfo.GiaEnergy.otherIngredients",
      additionalInfo: [
        "pages.shop.productInfo.GiaEnergy.additionalInfo.suggestedUse",
        "pages.shop.productInfo.GiaEnergy.additionalInfo.storage",
        "pages.shop.productInfo.GiaEnergy.additionalInfo.riskAndSideEffects",
      ],
    },
  },
];
