import React from "react";
import { useTranslation } from "react-i18next";
import OfferedProductCard from "components/special-offers/sub-components/OfferedProductCard";
import { COMBO_PRODUCTS } from "utils/constants/data/products";
import "./SpecialOffers.scss";

const SpecialOffers = () => {
  const { t } = useTranslation();
  return (
    <div className="SpecialOffers">
      <div className="SectionContainer">
        <h2 className="SectionDescription">
          {t("components.specialOffers.title")}
        </h2>
        <div className="OfferedProducts">
          {COMBO_PRODUCTS.map((product) => {
            return <OfferedProductCard key={product.id} product={product} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default SpecialOffers;
