import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ICON_ARROW_TO_UP from "assets/icons/arrows/arrow-to-up.svg";
import ICON_ARROW_TO_DOWN from "assets/icons/arrows/arrow-to-down.svg";
import "./IngredientsTable.scss";

const IngredientsTable = ({
  ingredients,
  theme,
  imgSrc,
  addToCartBtnClassName,
  onAdd,
}) => {
  const { t } = useTranslation();
  return (
    <section className="SectionContainer">
      <div className={`IngredientsTableContainer ${theme}`}>
        {imgSrc && (
          <>
            <img className="ImgLeft" alt="productImg" src={imgSrc} />
            <img className="ImgRight" alt="productImg" src={imgSrc} />
          </>
        )}
        <div className="IngredientsTable">
          <h2>{t("pages.shop.productInfo.containsLabel")} :</h2>
          {ingredients.contains.map((ingredient, i) => {
            return (
              <div className="Ingredient" key={i}>
                <div>
                  <p>{t(ingredient.name)}</p>
                  <p className="IngredientValue">{ingredient.value}</p>
                </div>
                <ul className="IngredientDetails">
                  {ingredient.details &&
                    ingredient.details.map((detail, index) => {
                      return <li key={index}>{t(detail)}</li>;
                    })}
                </ul>
              </div>
            );
          })}
          <div className="OtherIngredients">
            <h3>{t("pages.shop.productInfo.otherIngredientsLabel")} : </h3>
            <p>{t(ingredients.otherIngredients)}</p>
          </div>
          <div className="AdditionalInfo">
            {ingredients.additionalInfo.map((info, index) => {
              return <p key={index}>{t(info)}</p>;
            })}
          </div>
        </div>
      </div>
      <div>
        <img src={ICON_ARROW_TO_DOWN} alt="arrow" className="ToDown" />
        <button className={addToCartBtnClassName} onClick={onAdd}>
          {t("buttons.addToCart")}
        </button>
        <img src={ICON_ARROW_TO_UP} alt="arrow" className="ToUp" />
      </div>
    </section>
  );
};

IngredientsTable.propTypes = {
  ingredients: PropTypes.object.isRequired,
  theme: PropTypes.string,
  imgSrc: PropTypes.string,
  addToCartBtnClassName: PropTypes.string,
  onAdd: PropTypes.func.isRequired,
};
export default IngredientsTable;
