export const ROUTE_NAME = {
  shop: "shop",
  info: "info",
  checkout: "checkout",
  home: "homeemoh",
};

export const QUERY_PARAMS = {
  products: "products",
  openBasket: "openBasket",
};
