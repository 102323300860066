import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay } from "swiper";
import { useTranslation } from "react-i18next";
import { REVIEWERS_LIST } from "utils/constants/data/reviewers";
import { getTabletBreakpoint } from "utils/global";
import "./Reviews.scss";

const Reviews = () => {
  const { t } = useTranslation();
  return (
    <section className="ReviewSection">
      <div className="SectionContainer">
        <h3 className="SectionDescription">{t("pages.home.reviews.title")}</h3>
      </div>
      <Swiper
        slidesPerView={"auto"}
        spaceBetween={24}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
          waitForTransition: true,
        }}
        loop={true}
        loopedSlides={4}
        centeredSlides={true}
        modules={[Autoplay]}
        grabCursor={true}
        className="TestimonialsSlider"
        breakpoints={{
          [getTabletBreakpoint()]: {
            spaceBetween: 40,
          },
        }}
      >
        {REVIEWERS_LIST.map((reviewer, index) => (
          <SwiperSlide key={index} className="ReviewerSlide">
            <div className="ReviewerSlideImg">
              <img src={reviewer.reviewerImg} />
              <p className="ReviewerSlideMessage"> {reviewer.review}</p>
            </div>
            <p className="ReviewerSlideName">{reviewer.name} </p>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default Reviews;
