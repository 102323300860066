import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { addProduct } from "redux/slices/basketStore";
import { PRODUCTS } from "utils/constants/data/products";
import BuyProductSlide from "pages/home/components/buy-product-carousel/sub-components/BuyProductSlide";
import { PRODUCT_INFO_LIST } from "utils/constants/data/shop-products";
import { getTabletBreakpoint, getMobileBreakpoint } from "utils/global";
import { getElementByID } from "utils/global";
import { ReactComponent as ArrowRightIcon } from "assets/icons/arrows/slick-slide/arrow-right.svg";
import { ReactComponent as ArrowLeftIcon } from "assets/icons/arrows/slick-slide/arrow-left.svg";
import "./BuyProductCarousel.scss";

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <span className="prev-arrow" onClick={onClick}>
      <ArrowLeftIcon />
    </span>
  );
};

PrevArrow.propTypes = {
  onClick: PropTypes.func,
};

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <span className="next-arrow" onClick={onClick}>
      <ArrowRightIcon />
    </span>
  );
};

NextArrow.propTypes = {
  onClick: PropTypes.func,
};

const CAROUSEL_SETTINGS = {
  dots: false,
  arrows: false,
  slidesToShow: 4,
  infinite: false,
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: getTabletBreakpoint(),
      settings: {
        slidesToShow: 2,
        infinite: true,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 3000,
      },
    },
    {
      breakpoint: getMobileBreakpoint(),
      settings: {
        slidesToShow: 1,
        infinite: true,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 3000,
      },
    },
  ],
};

const BuyProductCarousel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleAdd = (id) => {
    dispatch(addProduct(getElementByID(id, PRODUCTS)));
  };

  return (
    <div className="BuyProductCarouselSection">
      <h1 className="SectionDescription">
        {t("pages.shop.productInfo.ourProducts")}
      </h1>
      <Slider className="BuyProductCarousel" {...CAROUSEL_SETTINGS}>
        {PRODUCT_INFO_LIST.map((product) => (
          <BuyProductSlide
            key={product.id}
            imgSrc={product.imgSrc}
            title={t(product.name)}
            price={product.price}
            onAdd={() => {
              handleAdd(product.id);
            }}
          />
        ))}
      </Slider>
    </div>
  );
};
export default BuyProductCarousel;
