export const POLICIES = {
  terms: {
    title: "components.policies.terms.title",
    header: "components.policies.header",
    body: [
      {
        heading: "components.policies.terms.process.title",
        content: "components.policies.terms.process.content",
      },
      {
        heading: "components.policies.terms.prices.title",
        content: "components.policies.terms.prices.content",
      },
      {
        heading: "components.policies.terms.warranty.title",
        content: "components.policies.terms.warranty.content",
      },
      {
        heading: "components.policies.terms.modification.title",
        content: "components.policies.terms.modification.content",
      },
    ],
  },

  privacy: {
    title: "components.policies.privacy.title",
    header: "components.policies.header",
    body: [
      {
        heading: "components.policies.privacy.dataProtection.title",
        content: "components.policies.privacy.dataProtection.content",
      },
      {
        heading: "components.policies.privacy.governingLow.title",
        content: "components.policies.privacy.governingLow.content",
      },
    ],
  },
  return: {
    title: "components.policies.return.title",
    header: "components.policies.header",
    body: [
      {
        heading: "components.policies.return.userEligibility.title",
        content: "components.policies.return.userEligibility.content",
      },
      {
        heading: "components.policies.return.serviceDescription.title",
        content: "components.policies.return.serviceDescription.content",
      },
    ],
  },
};
