import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getTabletBreakpoint } from "utils/global";
import useWindowSize from "utils/hooks/useWindowSize";
import ICON_BASKET from "assets/icons/basket/basket-orange.svg";
import ICON_ARROW_DOWN from "assets/icons/arrows/arrow-down-orange.svg";
import Cart from "components/cart/Cart";
import CartReceipt from "components/cart-receipt/CartReceipt";
import "./BasketSummary.scss";

const TABLET_SIZE = getTabletBreakpoint();
const BasketSummary = () => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    size: { width },
  } = useWindowSize();
  const totalPrice = useSelector((state) => state.basket.total);
  const { t } = useTranslation();

  return (
    <div className="BasketSummaryContainer">
      <div className="SummaryControl" onClick={() => setIsOpen(!isOpen)}>
        <div className="SummaryToggleContent">
          <img src={ICON_BASKET} alt={"basket"} />
          <p>
            {isOpen
              ? t("components.basket.hideOrderSummary")
              : t("components.basket.showOrderSummary")}
          </p>
          <img
            className={
              isOpen ? "RotateIcon SummaryToggleArrow" : "SummaryToggleArrow "
            }
            src={ICON_ARROW_DOWN}
            alt="arrow"
          />
        </div>
        <p className="TotalPrice">${totalPrice}</p>
      </div>
      <div
        className={`BasketSummary SectionContainer ${
          width < TABLET_SIZE ? (isOpen ? "ShowSummary " : "HideSummary ") : ""
        }`}
      >
        <Cart />
        <CartReceipt />
      </div>
    </div>
  );
};

export default BasketSummary;
