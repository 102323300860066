import variables from "assets/styles/_variables.scss";

export const generateUID = () => {
  return `${Date.now().toString(36)}-${Math.random().toString(36)}`;
};

export const getDesktopBreakpoint = (withPixel) => {
  return withPixel
    ? variables["desktopBreakpoint"]
    : parseInt(variables["desktopBreakpoint"].slice(0, -2));
};
export const getTabletBreakpoint = (withPixel) => {
  return withPixel
    ? variables["tabletBreakpoint"]
    : parseInt(variables["tabletBreakpoint"].slice(0, -2));
};
export const getMobileBreakpoint = (withPixel) => {
  return withPixel
    ? variables["mobileBreakpoint"]
    : parseInt(variables["mobileBreakpoint"].slice(0, -2));
};

export const getElementByID = (id, object) => {
  for (const key in object) {
    if (object[key].id === id) {
      return object[key];
    }
  }
  return null;
};

export const findProductById = (id, products, comboProducts) => {
  // Check in PRODUCTS constant
  for (const key in products) {
    if (products[key].id === id) {
      return products[key];
    }
  }

  // Check in COMBO_PRODUCTS constant
  for (const product of comboProducts) {
    if (product.id === id) {
      return product;
    }
  }

  return null;
};
