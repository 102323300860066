import { useDispatch, useSelector } from "react-redux";
import { updateAppVersion } from "redux/slices/appStore";

function useAppVersioning() {
  const dispatch = useDispatch();
  const currentAppVersion = useSelector((state) => state.app.appVersion);
  const newAppVersion = parseInt(process.env.REACT_APP_APP_VERSION);

  if (
    newAppVersion &&
    (!currentAppVersion || newAppVersion > currentAppVersion)
  ) {
    dispatch(updateAppVersion(newAppVersion));
    window.location.reload();
  }
}

export default useAppVersioning;
