import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { LANGUAGES } from "utils/constants/language";
import TRANSLATION_EN from "utils/constants/i18n/en/en-main";
import TRANSLATION_RU from "utils/constants/i18n/ru/ru-main";
import TRANSLATION_AZ from "utils/constants/i18n/az/az-main";

i18n.use(initReactI18next).init({
  debug: true,
  fallbackLng: LANGUAGES.en.code,
  interpolation: {
    escapeValue: false,
  },
  resources: {
    [LANGUAGES.en.code]: {
      translation: TRANSLATION_EN,
    },
    [LANGUAGES.ru.code]: {
      translation: TRANSLATION_RU,
    },
    [LANGUAGES.az.code]: {
      translation: TRANSLATION_AZ,
    },
  },
});

export default i18n;
