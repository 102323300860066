import React from "react";
import { useTranslation } from "react-i18next";
import ProductInfoCard from "pages/home/components/home-product-info/sub-components/ProductInfoCard";
import { FEATURED_PRODUCTS } from "utils/constants/data/products";
import "./HomeProductInfo.scss";

const HomeProductInfo = () => {
  const { t } = useTranslation();

  return (
    <section className="HomeProductInfoWrapper">
      {FEATURED_PRODUCTS.map((product, index) => (
        <ProductInfoCard
          imgSrc={product.imgPath}
          key={index}
          description={t(product.description)}
          linkTo={product.link}
        />
      ))}
    </section>
  );
};

export default HomeProductInfo;
