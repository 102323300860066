import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { addProduct } from "redux/slices/basketStore";
import ICON_ARROW_TOP_RIGHT_BLACK from "assets/icons/arrows/arrow-top-right-black.svg";
import "./OfferedProductCard.scss";

const OfferedProductCard = ({ product }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div className="OfferedProduct">
      <div className="ProductDetails">
        <div>
          <div className="ProductName">
            <h3>{t(product.title).slice(0, -5)} </h3>
            <span>
              <h3>{t("components.specialOffers.combo")}</h3>
              <img src={ICON_ARROW_TOP_RIGHT_BLACK} />
            </span>
          </div>
          <p className="Price">
            {t("buttons.price")}: ${product.price}
          </p>
          <p className="ProductInfo">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
        </div>

        <button
          onClick={() => dispatch(addProduct(product))}
          className="AddToCartBtn"
        >
          {t("buttons.addToCart")}
        </button>
      </div>
      <div className="ProductImage">
        <img src={product.img} />
      </div>
    </div>
  );
};

OfferedProductCard.propTypes = {
  product: PropTypes.object.isRequired,
};

export default OfferedProductCard;
