import React from "react";
import PropTypes from "prop-types";
import { ACTION_NAMES } from "utils/constants/global";
import ICON_WHATSAPP from "assets/icons/social-media/icon-whatsapp.svg";
import ICON_INSTAGRAM from "assets/icons/social-media/icon-instagram.svg";
import ICON_PHONE from "assets/icons/social-media/icon-phone.svg";
import ICON_EMAIL from "assets/icons/social-media/icon-email.svg";
import "./ActionButton.scss";

const ActionButton = ({ type }) => {
  const BUTTON_LINKS = {
    [ACTION_NAMES.instagram]: {
      link: "https://www.instagram.com/giavitamins/",
      icon: ICON_INSTAGRAM,
    },
    [ACTION_NAMES.whatsapp]: {
      link: "https://api.whatsapp.com/send?phone=+994552090072",
      icon: ICON_WHATSAPP,
    },
    [ACTION_NAMES.email]: {
      link: "mailto:hello@gia-vitamins.com",
      icon: ICON_EMAIL,
    },
    [ACTION_NAMES.map]: {
      link: "https://goo.gl/maps/8J7HcuoHCfjxHK2Q8",
      icon: ICON_EMAIL,
    },
    [ACTION_NAMES.mobile]: {
      link: "tel:+994552090072",
      icon: ICON_PHONE,
    },
  };

  return (
    <a
      className="ActionButtonWrapper"
      href={BUTTON_LINKS[type].link}
      target="_blank"
      rel="noreferrer"
    >
      <img src={BUTTON_LINKS[type].icon} alt="contact-icon" />
    </a>
  );
};

export default ActionButton;

ActionButton.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
};
