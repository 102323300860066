import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ICON_ARROW_TOP_RIGHT from "assets/icons/arrows/arrow-top-right.svg";
import "./AboutUs.scss";

const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <Link to={"#"}>
      <div className="AboutUs">
        <div className="SectionContainer">
          <img src={ICON_ARROW_TOP_RIGHT} />
          <h2>{t("pages.home.aboutUs.title")}</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
            ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua...
          </p>
        </div>
      </div>
    </Link>
  );
};

export default AboutUs;
