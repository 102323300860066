import React from "react";
import { useTranslation } from "react-i18next";
import { PRODUCT_INFO_LIST } from "utils/constants/data/shop-products";
import ShopProductCard from "pages/shop/components/shop-product-info/sub-components/ShopProductCard";
import "./ShopProductInfo.scss";

const ShopProductInfo = () => {
  const { t } = useTranslation();

  return (
    <section className="ShopProductInfo">
      <div className="SectionContainer">
        <h2 className="SectionDescription">
          {t("pages.shop.productInfo.ourProducts")}
        </h2>
      </div>
      <div className="Products">
        {PRODUCT_INFO_LIST.map((product) => (
          <ShopProductCard product={product} key={product.id} />
        ))}
      </div>
    </section>
  );
};

export default ShopProductInfo;
