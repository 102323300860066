import React from "react";
import PropTypes from "prop-types";
import IngredientLabels from "components/ingredient-labels/IngredientLabels";
import "./ProductDetails.scss";

const ProductDetails = ({ name, subTitle, infoImgSrc }) => {
  return (
    <section className="ProductDetails SectionContainer">
      <div className="DetailsContainer">
        <div>
          <h3>{name}</h3>
          <p>{subTitle}</p>
        </div>
        <img src={infoImgSrc} alt={name} />
      </div>
      <IngredientLabels />
    </section>
  );
};

ProductDetails.propTypes = {
  subTitle: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  infoImgSrc: PropTypes.string.isRequired,
};

export default ProductDetails;
