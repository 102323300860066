const TRANSLATION = {
  buttons: {
    learnMore: "узнать больше",
    addToCart: "добавить в корзину",
    price: "цена",
  },
  pages: {
    home: {
      aboutUs: {
        title: "о нас",
      },
      reviews: {
        title: "отзывы",
      },
      riskFree: {
        title: "Попробуйте, без риска!",
        description:
          "Если вы не удовлетворены нашим продуктом, просто свяжитесь с нами, и мы предоставим вам полный, 100% беззаботный возврат средств.",
      },
    },
    shop: {
      productInfo: {
        ourProducts: "наши продукты",
      },
    },
  },
  components: {
    specialOffers: {
      title: "специальные предложения",
    },
    basket: {
      cart: "корзина",
      total: "всего",
      discount: "скидка",
    },
    ingredientLabels: {},
    customSelect: {
      lang: "Язык",
      curr: "Валюта",
      language: {
        en: "Английский",
        az: "Азербайджанский",
        ru: "Русский",
      },
      currency: {
        azn: "Азербайджанский манат",
        usd: "Доллары США",
        eur: "Евро",
      },
    },
  },
};

export default TRANSLATION;
