import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FAQS_DATA } from "utils/constants/data/faq-data";
import ICON_PLUS from "assets/icons/math-operators/plus.svg";
import ICON_MINUS from "assets/icons/math-operators/minus.svg";
import "./FaqList.scss";

const FaqList = () => {
  const [activeQuestion, setActiveQuestion] = useState(null);
  const { t } = useTranslation();

  const handleQuestionClick = (groupIndex, faqIndex) => {
    setActiveQuestion(activeQuestion === faqIndex ? null : faqIndex);
  };

  return (
    <div className="FaqListWrapper">
      <h1 className="SectionDescription">{t("pages.home.faq.title")}</h1>
      {FAQS_DATA.map((faqGroup, groupIndex) => (
        <div className="FaqListGroup" key={groupIndex}>
          <h3 className="FaqListGroupTitle">{t(faqGroup.type)}</h3>
          {faqGroup.faqs.map((faq, faqIndex) => {
            const questionIndex = groupIndex * 100 + faqIndex;
            return (
              <div
                className={`FaqListItem ${
                  activeQuestion === questionIndex ? "active" : ""
                }`}
                key={questionIndex}
              >
                <div
                  onClick={() => handleQuestionClick(groupIndex, questionIndex)}
                  className="FaqListItemQuestion"
                >
                  <h4> {t(faq.question)} </h4>
                  <span>
                    <img
                      src={
                        activeQuestion === questionIndex
                          ? ICON_MINUS
                          : ICON_PLUS
                      }
                      alt="see-more"
                    />
                  </span>
                </div>
                <p className="FaqListItemAnswer">{t(faq.answer)}</p>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};
export default FaqList;
