import React, { useEffect, useState } from "react";
import { useLockBodyScroll } from "utils/hooks/useLockBodyScroll";
import SPINNING_LOGO from "assets/images/logo/loadingLogo.png";
import IMG_ENERGY from "assets/images/products/buy-products-carousel/energy.png";
import IMG_KIDS from "assets/images/products/buy-products-carousel/kids.png";
import IMG_HAIR from "assets/images/products/buy-products-carousel/hair.png";
import IMG_SLEEP from "assets/images/products/buy-products-carousel/sleep.png";
import "./LoadingProduct.scss";

const LoadingProduct = () => {
  useLockBodyScroll();
  const [currentIndex, setCurrentIndex] = useState(0);
  const products = [IMG_ENERGY, IMG_KIDS, IMG_HAIR, IMG_SLEEP];

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentIndex((currentIndex + 1) % products.length);
    }, 500);

    return () => clearTimeout(timer);
  }, [currentIndex, products.length]);

  return (
    <div className="LoadingProduct">
      <div className="LoadingProductSpinner">
        <img src={SPINNING_LOGO} className="SpinningLogo" />
        {products.map((products, index) => (
          <img
            key={index}
            src={products}
            alt={`GIA Product  ${index + 1}`}
            className={index === currentIndex ? "active" : ""}
          />
        ))}
      </div>
    </div>
  );
};

export default LoadingProduct;
