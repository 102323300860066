import React, { useState } from "react";
import PropTypes from "prop-types";
import useOutsideClick from "utils/hooks/useOutsideClick";
import { useTranslation } from "react-i18next";
import { SELECT_MENU } from "utils/constants/data/customSelectOptions";
import ICON_ARROW_DOWN from "assets/icons/arrows/arrow-down-black.svg";
import "./CustomSelect.scss";

const types = {
  LANGUAGE: "language",
  CURRENCY: "currency",
};

const CustomSelect = ({ type, onSwitchLanguage }) => {
  const { t } = useTranslation();
  const { isOpen, setIsOpen, catMenu } = useOutsideClick();
  const [selectedOption, setSelectedOption] = useState(SELECT_MENU[type][0]);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    if (type === types.LANGUAGE && onSwitchLanguage) {
      onSwitchLanguage(option.value);
    }
  };

  const renderOptions = () => {
    const options = SELECT_MENU[type];
    return options.map((option) => (
      <li
        key={option.value}
        value={option.value}
        onClick={() => handleSelectOption(option)}
      >
        {type === types.LANGUAGE && option.icon && (
          <img src={option.icon} alt="option icon" />
        )}
        {type === types.CURRENCY && option.icon && (
          <span className="CustomSelectMenuOptionIcon">{option.icon}</span>
        )}
        <div className="CustomSelectMenuOptionText">
          <p> {type === types.LANGUAGE ? t(option.opt) : option.opt}</p>
          <span>
            {type === types.CURRENCY
              ? t(option.originalOpt)
              : option.originalOpt}
          </span>
        </div>
      </li>
    ));
  };

  return (
    <div>
      {isOpen && (
        <div className="CustomSelectOverlay" onClick={() => setIsOpen(false)} />
      )}
      <div className="CustomSelect" ref={catMenu}>
        <div
          className="CustomSelectSelectedOption"
          onClick={handleToggleDropdown}
        >
          {type === types.LANGUAGE && (
            <img src={selectedOption.icon} alt="selected option" />
          )}
          {type === types.CURRENCY && (
            <span className="CustomSelectedMenuOptionIcon">
              {selectedOption.icon}
            </span>
          )}
          <div>
            <p>{selectedOption.label}</p>
            <span>
              {type === types.LANGUAGE
                ? t("components.customSelect.lang")
                : t("components.customSelect.curr")}
            </span>
          </div>
          <span className="CustomSelectArrowIcon">
            <img
              src={ICON_ARROW_DOWN}
              alt="arrow down"
              className={isOpen ? "open" : ""}
            />
          </span>
        </div>
        {isOpen && <ul className="CustomSelectMenu">{renderOptions()}</ul>}
      </div>
    </div>
  );
};

export default CustomSelect;

CustomSelect.propTypes = {
  type: PropTypes.oneOf(["language", "currency"]),
  onSwitchLanguage: PropTypes.func,
};
