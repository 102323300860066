import axios from "axios";
import { HEADERS } from "utils/constants/api";

export const BACKEND_BASE_URL = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_BACKEND_URL_VERSION}`;

const apiDefaults = {
  baseURL: BACKEND_BASE_URL,
  // withCredentials: true, // causing CORS problems in AWS deploy
  headers: {
    Accept: "application/json",
  },
};

const authRequestInterceptor = (config) => {
  config.headers[HEADERS.keys.auth] = `${HEADERS.values.authType} token`;
  return config;
};

const responseInterceptor = (response) => {
  return response;
};

const responseErrorInterceptor = (response) => {
  const statusCode = response.response.status;
  const statusText = response.response.statusText;
  const message = response.response.data.message;
  console.log(
    "Generic axios error intercept. Code: ",
    statusCode,
    ", statusText: ",
    statusText,
    ", message: ",
    message
  );
  return Promise.reject(response);
};

export const AXIOS_NO_AUTH = axios.create(apiDefaults);
AXIOS_NO_AUTH.interceptors.response.use(
  responseInterceptor,
  responseErrorInterceptor
);

export const AXIOS = axios.create(apiDefaults);
AXIOS.interceptors.request.use(authRequestInterceptor);
AXIOS.interceptors.response.use(responseInterceptor, responseErrorInterceptor);

export default AXIOS;
