import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ICON_CLOSE from "assets/icons/close/close.svg";
import useOutsideClick from "utils/hooks/useOutsideClick";
import "./Modal.scss";

const Modal = ({ children, buttonName, handlePayment }) => {
  const {
    isOpen: isModalOpen,
    setIsOpen: setIsModalOpen,
    catMenu: modalRef,
  } = useOutsideClick();

  useEffect(() => {
    setIsModalOpen(true);
  }, []);

  const handleModalButtonClick = () => {
    setIsModalOpen(false);
    handlePayment && handlePayment();
  };

  return (
    <>
      {isModalOpen && (
        <div className="Modal">
          <div className="ModalContent" ref={modalRef}>
            <div className="ModalContentHeader">
              <button className="ModalClose" onClick={handleModalButtonClick}>
                <img src={ICON_CLOSE} alt="Close" />
              </button>
            </div>
            {children}
            <button className="ModalButton" onClick={handleModalButtonClick}>
              {buttonName}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  buttonName: PropTypes.string,
  handlePayment: PropTypes.func,
};

export default Modal;
