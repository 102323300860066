export const FAQS_DATA = [
  {
    type: "pages.home.faq.aboutProduct.title",
    faqs: [
      {
        question: "pages.home.faq.aboutProduct.faqs.faq1.question",
        answer: "pages.home.faq.aboutProduct.faqs.faq1.answer",
      },
      {
        question: "pages.home.faq.aboutProduct.faqs.faq2.question",
        answer: "pages.home.faq.aboutProduct.faqs.faq2.answer",
      },
      {
        question: "pages.home.faq.aboutProduct.faqs.faq3.question",
        answer: "pages.home.faq.aboutProduct.faqs.faq3.answer",
      },
      {
        question: "pages.home.faq.aboutProduct.faqs.faq4.question",
        answer: "pages.home.faq.aboutProduct.faqs.faq4.answer",
      },
      {
        question: "pages.home.faq.aboutProduct.faqs.faq5.question",
        answer: "pages.home.faq.aboutProduct.faqs.faq5.answer",
      },
    ],
  },
  {
    type: "pages.home.faq.deliveryAndShipping.title",
    faqs: [
      {
        question: "pages.home.faq.deliveryAndShipping.faqs.faq1.question",
        answer: "pages.home.faq.deliveryAndShipping.faqs.faq1.answer",
      },
      {
        question: "pages.home.faq.deliveryAndShipping.faqs.faq2.question",
        answer: "pages.home.faq.deliveryAndShipping.faqs.faq2.answer",
      },
      {
        question: "pages.home.faq.deliveryAndShipping.faqs.faq3.question",
        answer: "pages.home.faq.deliveryAndShipping.faqs.faq3.answer",
      },
      {
        question: "pages.home.faq.deliveryAndShipping.faqs.faq4.question",
        answer: "pages.home.faq.deliveryAndShipping.faqs.faq4.answer",
      },
      {
        question: "pages.home.faq.deliveryAndShipping.faqs.faq5.question",
        answer: "pages.home.faq.deliveryAndShipping.faqs.faq5.answer",
      },
    ],
  },
];
