import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  decrement,
  increment,
  incrementByAmount,
  incrementThunk,
  setDarkMode,
} from "redux/slices/appStore";
import useAsync from "utils/hooks/useAsync";
import { STORE_NAMES } from "utils/constants/redux";
import { getHelloWorldAsync } from "utils/api/services/general";
import { BACKEND_BASE_URL } from "utils/api/axios";
import "./Info.scss";

const Info = () => {
  const reduxStoreApp = useSelector((state) => state[STORE_NAMES.APP]);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { result: beHello } = useAsync(getHelloWorldAsync, {
    immediate: true,
  });

  const WINDOW = {
    origin: window.location.origin,
    protocol: window.location.protocol,
    host: window.location.host,
    hostName: window.location.hostName,

    devicePixelRatio: window.devicePixelRatio,
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    outerHeight: window.outerHeight,
    outerWidth: window.outerWidth,
    availHeight: window.screen.availHeight,
    availWidth: window.screen.availWidth,
  };

  const NAVIGATOR = {
    platform: navigator.platform,
    language: navigator.language,
    geolocation: "Requires HTTPS ??",
    userAgent: navigator.userAgent,
    vendor: navigator.vendor,
    appName: navigator.appName,
    appCodeName: navigator.appCodeName,
    appCodeVersion: navigator.appVersion,
  };

  return (
    <div className="InfoController">
      {/* --- Redux --- */}
      <div className="InfoContainer">
        <h2>Redux</h2>
        <h6 className={"Row"}>
          AppStore state: <span> {reduxStoreApp.value}</span>
        </h6>
        <button
          onClick={() => {
            dispatch(increment());
          }}
        >
          Increment
        </button>
        <button
          onClick={() => {
            dispatch(decrement());
          }}
        >
          Decrement
        </button>
        <button
          onClick={() => {
            dispatch(incrementByAmount(5));
          }}
        >
          Increment by 5
        </button>
        <button
          onClick={() => {
            dispatch(incrementThunk(5));
          }}
        >
          Increment then Decrement Thunk
        </button>
      </div>

      {/* --- custom --- */}
      <div className={"InfoContainer"}>
        <h2>Custom</h2>
        <h6 className={"Row"}>
          MultiLang: <span>{t("welcome")}</span>
        </h6>
        <h6 className={"Row"}>
          Ligh-Dark Mode:
          <span
            className="LighDarkMode"
            onClick={() => {
              dispatch(setDarkMode(!reduxStoreApp.isDarkMode));
            }}
          >
            {reduxStoreApp.isDarkMode ? "Dark" : "Light"}
          </span>
        </h6>
        <h6 className={"Row"}>
          Backend baseUrl: <span>{BACKEND_BASE_URL}</span>
        </h6>
        <h6 className={"Row"}>
          BE test (../hello): <span>{beHello || "null"}</span>
        </h6>
      </div>

      {/* --- Geo Location --- */}
      <div className={"InfoContainer"}>
        <h2>GeoLocation</h2>
        <h6 className={"Row"}>
          source: <span>https://geolocation-db.com/json/</span>
        </h6>
        {reduxStoreApp.userGeolocation &&
          Object.keys(reduxStoreApp.userGeolocation).map((key) => {
            return (
              <h6 key={key} className={"Row"}>
                {key}: <span>{reduxStoreApp.userGeolocation[key]}</span>
              </h6>
            );
          })}
      </div>

      <div className={"InfoContainer"}>
        <h2>Env Variables</h2>
        {Object.keys(process.env).map((key) => {
          return (
            <h6 key={key} className={"Row"}>
              {key}: <span>{process.env[key]}</span>
            </h6>
          );
        })}
      </div>

      <div className={"InfoContainer"}>
        <h2>Window</h2>
        {Object.keys(WINDOW).map((key) => {
          return (
            <h6 key={key} className={"Row"}>
              {key}: <span>{WINDOW[key]}</span>
            </h6>
          );
        })}
      </div>

      <div className={"InfoContainer"}>
        <h2>Navigator</h2>
        {Object.keys(NAVIGATOR).map((key) => {
          return (
            <h6 key={key} className={"Row"}>
              {key}: <span>{NAVIGATOR[key]}</span>
            </h6>
          );
        })}
      </div>
    </div>
  );
};

export default Info;
