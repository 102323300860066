import React from "react";
import { Route, Routes } from "react-router-dom";
import useGeoLocation from "utils/hooks/useGeoLocation";
import useCssRootVars from "utils/hooks/useCssRootVars";
import useAppVersioning from "utils/hooks/useAppVersioning";
import useProtection from "utils/hooks/useProtection";
import { ROUTE_NAME } from "utils/constants/routes";
import { getBankAuditEnabled } from "utils/envHelpers";
import useReduxVersioning from "utils/hooks/useReduxVersioning";
import Shop from "pages/shop/Shop";
import Info from "pages/info/Info";
import NotFound from "pages/not-found/NotFount";
import Home from "pages/home/Home";
import Checkout from "pages/checkout/Checkout";
import ComingSoon from "components/coming-soon/ComingSoon";
import useSharedLinkParams from "utils/hooks/useSharedLinkParams";

const AppRouter = () => {
  useGeoLocation();
  useCssRootVars();
  // useProtection();
  useAppVersioning();
  useReduxVersioning();
  useSharedLinkParams();

  return (
    <div className="App">
      <Routes>
        {getBankAuditEnabled() ? (
          <Route index element={<ComingSoon />} />
        ) : (
          <Route index element={<Home />} />
        )}
        <Route path={ROUTE_NAME.shop} element={<Shop />} />
        <Route path={ROUTE_NAME.home} element={<Home />} />
        <Route path={ROUTE_NAME.info} element={<Info />} />
        <Route path={ROUTE_NAME.checkout} element={<Checkout />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default AppRouter;
