import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetCheckoutStore } from "redux/slices/checkoutStore";
import { resetBasket } from "redux/slices/basketStore";
import CheckoutHeader from "pages/checkout/components/checkout-header/CheckoutHeader";
import Information from "pages/checkout/components/checkout-steps/information/Information";
import Shipping from "pages/checkout/components/checkout-steps/shipping/Shipping";
import Payment from "pages/checkout/components/checkout-steps/payment/Payment";
import BasketSummary from "pages/checkout/components/basket-summary/BasketSummary";
import CheckoutFooter from "pages/checkout/components/checkout-footer/CheckoutFooter";
import PaymentSuccess from "pages/checkout/components/checkout-steps/sub-components/payment-success/PaymentSuccess";
import PaymentError from "pages/checkout/components/checkout-steps/sub-components/payment-error/PaymentError";
import { STEP_NAMES } from "utils/constants/global";
import { PAYMENT_STATUS } from "utils/constants/data/payment-status";
import { getTabletBreakpoint } from "utils/global";
import useWindowSize from "utils/hooks/useWindowSize";
import "./Checkout.scss";

const TABLET_SIZE = getTabletBreakpoint();

const Checkout = () => {
  const [currentStep, setCurrentStep] = useState(STEP_NAMES.shipping);
  const [checkoutModal, setCheckoutModal] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const stepComponents = [Information, Shipping, Payment];
  const stepOrder = [
    STEP_NAMES.shipping,
    STEP_NAMES.information,
    STEP_NAMES.payment,
  ];
  const location = useLocation();
  const {
    size: { width },
  } = useWindowSize();

  useEffect(() => {
    let step = {
      step: currentStep,
    };
    setSearchParams(step, { replace: true });
    window.scrollTo(0, 0);
  }, [currentStep]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const statusParam = searchParams.get("s");

    if (statusParam === PAYMENT_STATUS.APPROVED_PARAM) {
      setCheckoutModal(<PaymentSuccess handleAccept={handlePaymentSuccess} />);
    } else if (
      statusParam === PAYMENT_STATUS.DECLINE_PARAM ||
      statusParam === PAYMENT_STATUS.CANCEL_PARAM
    ) {
      setCheckoutModal(<PaymentError />);
    }
  }, [location]);

  const handlePaymentSuccess = () => {
    dispatch(resetCheckoutStore());
    dispatch(resetBasket());
    localStorage.removeItem("wasClicked");
    navigate({
      pathname: "/",
      search: searchParams.toString(),
    });
  };

  const handleNextStep = () => {
    setCurrentStep((prevStep) => {
      const currentIndex = stepOrder.indexOf(prevStep);
      if (currentIndex < stepOrder.length - 1) {
        return stepOrder[currentIndex + 1];
      }
      return prevStep;
    });
  };

  const handleGoBack = () => {
    setCurrentStep((prevStep) => {
      const currentIndex = stepOrder.indexOf(prevStep);
      if (currentIndex > 0) {
        return stepOrder[currentIndex - 1];
      }
      return prevStep;
    });
  };

  const renderCurrentStep = () => {
    const currentStepIndex = stepOrder.indexOf(currentStep);
    if (currentStepIndex !== -1) {
      const Component = stepComponents[currentStepIndex];
      return <Component onNext={handleNextStep} onGoBack={handleGoBack} />;
    }
    return null;
  };

  return (
    <section className="Checkout ">
      <div className="CheckoutHeaderContainer">
        {width < TABLET_SIZE && (
          <CheckoutHeader steps={stepOrder} currentStep={currentStep} />
        )}
      </div>
      <div className="CheckoutContent">
        <div className="ProgressContainer SectionContainer">
          <div className="CurrentStepContainer">
            {width > TABLET_SIZE && (
              <div className="HeaderContainer ">
                <CheckoutHeader steps={stepOrder} currentStep={currentStep} />
              </div>
            )}
            {renderCurrentStep()}
          </div>
          <CheckoutFooter />
        </div>
        <BasketSummary />
      </div>
      {checkoutModal}
    </section>
  );
};

export default Checkout;
