import IMAGE_REVIEWER_1 from "assets/images/reviews/reviewers/dianne-russell.png";
import IMAGE_REVIEWER_2 from "assets/images/reviews/reviewers/jane-cooper.png";
import IMAGE_REVIEWER_3 from "assets/images/reviews/reviewers/marvin-mc-kinney.png";
import IMAGE_REVIEWER_4 from "assets/images/reviews/reviewers/kathryn-murphy.png";
import IMAGE_REVIEWER_5 from "assets/images/reviews/reviewers/ralph-edwards.png";

export const REVIEWERS_LIST = [
  {
    name: "Dianne Russell",
    review:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    reviewerImg: IMAGE_REVIEWER_1,
  },
  {
    name: "Jane Cooper",
    review:
      "Lorem ipsum dolor sit amet elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    reviewerImg: IMAGE_REVIEWER_2,
  },
  {
    name: "Marvin McKinney",
    review:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    reviewerImg: IMAGE_REVIEWER_3,
  },
  {
    name: "Kathryn Murphy",
    review:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do. ",
    reviewerImg: IMAGE_REVIEWER_4,
  },
  {
    name: "Ralph Edwards",
    review:
      "Lorem ipsum dolor sit amet elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    reviewerImg: IMAGE_REVIEWER_5,
  },
  {
    name: "Dianne Russell",
    review:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    reviewerImg: IMAGE_REVIEWER_1,
  },
  {
    name: "Jane Cooper",
    review:
      "Lorem ipsum dolor sit amet elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    reviewerImg: IMAGE_REVIEWER_2,
  },
  {
    name: "Marvin McKinney",
    review:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    reviewerImg: IMAGE_REVIEWER_3,
  },
  {
    name: "Kathryn Murphy",
    review:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do. ",
    reviewerImg: IMAGE_REVIEWER_4,
  },
  {
    name: "Ralph Edwards",
    review:
      "Lorem ipsum dolor sit amet elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    reviewerImg: IMAGE_REVIEWER_5,
  },
];
