import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import isEqual from "lodash/isEqual";

export const useAsyncBasic = (asyncFunction, immediate = true) => {
  const [status, setStatus] = useState("idle");
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);
  // The execute function wraps asyncFunction and
  // handles setting state for pending, value, and error.
  // useCallback ensures the below useEffect is not called
  // on every render, but only if asyncFunction changes.
  const execute = useCallback(() => {
    setStatus("pending");
    setValue(null);
    setError(null);
    return asyncFunction()
      .then((response) => {
        setValue(response.data);
        setStatus("success");
      })
      .catch((error) => {
        setError(error);
        setStatus("error");
      });
  }, [asyncFunction]);
  // Call execute if we want to fire it right away.
  // Otherwise, execute can be called later, such as
  // in an onClick handler.
  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [execute, immediate]);
  return { execute, status, value, error };
};

export const useAsync = (asyncFunction, options = {}) => {
  const { immediate = false, rawResult = false, onError, onSuccess } = options;
  const [result, setResult] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const execute = useCallback(
    (...args) => {
      setLoading(true);
      return asyncFunction(...args)
        .then((response) => {
          setResult(rawResult ? response : response.data);
          setError(false);
          if (onSuccess !== undefined) {
            if (typeof onSuccess === "function") {
              onSuccess(response);
            } else {
              throw TypeError(
                `Expected typeof onSuccess to be "function". Found: "${typeof onSuccess}"`
              );
            }
          }
        })
        .catch((error) => {
          setResult(null);
          setError(true);
          if (onError !== undefined) {
            if (typeof onError === "function") {
              onError(error);
            } else {
              throw TypeError(
                `Expected typeof onError to be "function". Found: "${typeof onError}"`
              );
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [asyncFunction]
  );

  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [execute, immediate]);
  return { execute, result, error, loading };
};

export const useAsyncHmtp = (asyncFunction, options = {}) => {
  const {
    immediate = false,
    params = [],
    defaultResults,
    defaultLoading,
    executeThrow,
    onError,
    onSuccess,
  } = options;
  const [loading, setLoading] = useState(defaultLoading || false);
  const [result, setResult] = useState(defaultResults);
  const [error, setError] = useState(null);
  const mountedRef = useRef(true);

  // The execute function wraps asyncFunction and
  // handles setting state for loading, results, and error.
  // useCallback ensures the below useEffect is not called
  // on every render, but only if asyncFunction changes.
  const execute = useCallback(
    (...args) => {
      setLoading(true);
      setError(null);
      return asyncFunction(...args)
        .then((response) => {
          if (!mountedRef.current) return null;
          setResult(response);
          setLoading(false);
          if (onSuccess !== undefined) {
            if (typeof onSuccess === "function") {
              onSuccess(response);
            } else {
              throw TypeError(
                `Expected typeof onSuccess to be "function". Found: "${typeof onSuccess}"`
              );
            }
          }
          return response;
        })
        .catch((error) => {
          if (!mountedRef.current) return null;
          setError(error);
          setLoading(false);
          if (onError !== undefined) {
            if (typeof onError === "function") {
              onError(error);
            } else {
              throw TypeError(
                `Expected typeof onError to be "function". Found: "${typeof onError}"`
              );
            }
          }
          if (executeThrow) {
            throw error;
          }
        });
    },
    [asyncFunction]
  );

  // This immediateFuncParams wraps the params array to be passed
  // to the asyncFunction when immediately run.
  // useMemo is created to do a deep comparison so that useEffect
  // correctly knows when params have changed.
  const prevParams = useRef();
  const immediateFuncParams = useMemo(() => {
    if (isEqual(prevParams.current, params)) {
      return prevParams.current;
    } else {
      prevParams.current = params;
      return params;
    }
  }, [params]);

  // Call execute if we want to fire it right away.
  // Otherwise execute can be called later, such as
  // in an onClick handler.
  useEffect(() => {
    if (immediate) {
      execute(...immediateFuncParams);
    }
  }, [execute, immediate, immediateFuncParams]);

  // Add memory leak fix based on
  // https://stackoverflow.com/questions/56450975/to-fix-cancel-all-subscriptions-and-asynchronous-tasks-in-a-useeffect-cleanup-f/60693711#60693711
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return {
    execute,
    loading,
    result,
    error,
    setResult,
    setError,
  };
};

export default useAsync;
