import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconTrash } from "assets/icons/basket/trash.svg";
import "./CartItem.scss";

const CartItem = ({
  onRemove,
  onAdd,
  count,
  price,
  title,
  imgSrc,
  onDeleteItem,
}) => {
  const { t } = useTranslation();

  return (
    <div className="CartItem">
      <div className="CartItemInfo">
        <div className="CartItemImg">
          <img src={imgSrc} alt="tablet" />
        </div>
        <div className="CartItemDetails">
          <p>{t(title)}</p>
          <div className="IncrementDecrementButtons">
            <button onClick={onRemove} className="DecreaseItemCount">
              -
            </button>
            <p> {count}</p>
            <button onClick={onAdd} className="IncreaseItemCount">
              +
            </button>
          </div>
        </div>
      </div>
      <div className="CartItemPrice">
        <h2>${price.toFixed(2)}</h2>
        <button onClick={onDeleteItem} className="TrashButton">
          <IconTrash />
        </button>
      </div>
    </div>
  );
};

export default CartItem;

CartItem.propTypes = {
  onRemove: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
};
