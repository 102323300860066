//Images for OFFERS Section
import IMAGE_SLEEP_COMBO from "assets/images/offered-products/sleep-combo.jpg";
import IMAGE_KIDS_COMBO from "assets/images/offered-products/kids-combo.jpg";
import IMAGE_HAIR_NAIL_COMBO from "assets/images/offered-products/hair-nail-combo.jpg";
import IMAGE_ENERGY_COMBO from "assets/images/offered-products/energy-combo.jpg";
//Images for SLIDES
import IMAGE_CALM_SLEEP from "assets/images/hero-slide-images/calm-sleep.jpg";
import IMAGE_YUMMY_GUMMY from "assets/images/hero-slide-images/yummy-gummy.jpg";
import IMAGE_HAIR_NAILS from "assets/images/hero-slide-images/hair-nails.jpg";
import IMAGE_ENERGY from "assets/images/hero-slide-images/energy.jpg";
//Images for FFEATURED_PRODUCTS
import IMG_PRODUCT_INFO_1 from "assets/images/products/home-product-info1.jpg";
import IMG_PRODUCT_INFO_2 from "assets/images/products/home-product-info2.jpg";
import IMG_PRODUCT_INFO_3 from "assets/images/products/home-product-info3.jpg";
import IMG_PRODUCT_INFO_4 from "assets/images/products/home-product-info-4.jpg";
//Images for INITIAL_STATE_BASKET
import IMG_GIA_ENERGY from "assets/images/products/buy-products-carousel/energy.png";
import IMG_GIA_HAIR_AND_NAILS from "assets/images/products/buy-products-carousel/hair.png";
import IMG_GIA_SLEEP from "assets/images/products/buy-products-carousel/sleep.png";
import IMG_GIA_KIDS from "assets/images/products/buy-products-carousel/kids.png";
//Icons for LABELS (Ingredient Labels Section)
import ICON_VEGAN from "assets/icons/ingredient-labels/vegan.svg";
import ICON_NO_GMO from "assets/icons/ingredient-labels/no-gmo.svg";
import ICON_GELATINE_FREE from "assets/icons/ingredient-labels/gelatine-free.svg";
import ICON_BIO from "assets/icons/ingredient-labels/bio.svg";
//Icons for ICON_DELIVERY (Shipping and Payment methods Section)
import ICON_DELIVERY from "assets/icons/payment-shipping/delivery.svg";
import ICON_PICKUP from "assets/icons/payment-shipping/pick-up.svg";
import ICON_MASTERCARD from "assets/icons/payment-shipping/mastercard.svg";
import ICON_CASH from "assets/icons/payment-shipping/cash.svg";

export const PRODUCT_TYPE = {
  sleep: "Sleep",
  hair: "Hair",
  kids: "Kids",
  energy: "Energy",
};

export const PRODUCTS = {
  energy: {
    id: 1,
    title: "products.energy.title",
    img: IMG_GIA_ENERGY,
    price: 25.0,
  },
  hairNail: {
    id: 2,
    title: "products.hairNail.title",
    img: IMG_GIA_HAIR_AND_NAILS,
    price: 30.0,
  },
  sleep: {
    id: 3,
    title: "products.sleep.title",
    img: IMG_GIA_SLEEP,
    price: 18.0,
  },
  kids: {
    id: 4,
    title: "products.kids.title",
    img: IMG_GIA_KIDS,
    price: 18.0,
  },
};

export const COMBO_PRODUCTS = [
  {
    id: 5,
    title: "products.energyCombo.title",
    img: IMAGE_ENERGY_COMBO,
    price: 50.0,
  },
  {
    id: 6,
    title: "products.hairNailCombo.title",
    img: IMAGE_HAIR_NAIL_COMBO,
    price: 60.0,
  },
  {
    id: 7,
    title: "products.sleepCombo.title",
    img: IMAGE_SLEEP_COMBO,
    price: 40.0,
  },
  {
    id: 8,
    title: "products.kidsCombo.title",
    img: IMAGE_KIDS_COMBO,
    price: 36.0,
  },
];

export const METHODS = {
  shippingMethods: [
    {
      title: "components.shippingPaymentMethods.delivery.title",
      imgSrc: ICON_DELIVERY,
      info: "components.shippingPaymentMethods.delivery.info",
    },
    {
      title: "components.shippingPaymentMethods.pickup.title",
      imgSrc: ICON_PICKUP,
      info: "components.shippingPaymentMethods.pickup.info",
    },
  ],
  paymentMethods: [
    {
      title: "components.shippingPaymentMethods.card.title",
      imgSrc: ICON_MASTERCARD,
      info: "components.shippingPaymentMethods.card.info",
    },
    {
      title: "components.shippingPaymentMethods.cash.title",
      imgSrc: ICON_CASH,
      info: "components.shippingPaymentMethods.cash.info",
    },
  ],
};

export const HOME_HERO_SLIDES = [
  {
    id: 1,
    title: "products.kids.title",
    description: "pages.shop.productInfo.GiaKids.subTitle",
    img: IMAGE_YUMMY_GUMMY,
    gradientColors: ["#A6B840", "#D27534", "#C73739"],
    productType: PRODUCT_TYPE.kids,
  },
  {
    id: 2,
    title: "products.sleep.title",
    description: "pages.shop.productInfo.GiaSleep.subTitle",
    img: IMAGE_CALM_SLEEP,
    gradientColors: ["#7D4FFF", "#31D6FC"],
    productType: PRODUCT_TYPE.sleep,
  },
  {
    id: 3,
    title: "products.hairNail.title",
    description: "pages.shop.productInfo.GiaHairNail.subTitle",
    img: IMAGE_HAIR_NAILS,
    gradientColors: ["#189E78", "#40A6AB"],
    productType: PRODUCT_TYPE.hair,
  },
  {
    id: 4,
    title: "products.energy.title",
    description: "pages.shop.productInfo.GiaEnergy.subTitle",
    img: IMAGE_ENERGY,
    gradientColors: ["#C60006", "#F56600"],
    productType: PRODUCT_TYPE.energy,
  },
];

export const LABELS = [
  {
    id: 1,
    icon: ICON_VEGAN,
    name: "components.ingredientLabels.label1.name",
    description: "components.ingredientLabels.label1.description",
  },
  {
    id: 2,
    icon: ICON_NO_GMO,
    name: "components.ingredientLabels.label2.name",
    description: "components.ingredientLabels.label2.description",
  },
  {
    id: 3,
    icon: ICON_GELATINE_FREE,
    name: "components.ingredientLabels.label3.name",
    description: "components.ingredientLabels.label3.description",
  },
  {
    id: 4,
    icon: ICON_BIO,
    name: "components.ingredientLabels.label4.name",
    description: "components.ingredientLabels.label4.description",
  },
];

export const FEATURED_PRODUCTS = [
  {
    name: "products.energy.title",
    description: "pages.home.productInfo.energy.description",
    link: "/shop",
    imgPath: IMG_PRODUCT_INFO_1,
  },
  {
    name: "products.sleep.title",
    description: "pages.home.productInfo.sleep.description",
    link: "/shop",
    imgPath: IMG_PRODUCT_INFO_2,
  },
  {
    name: "products.hairNail.title",
    description: "pages.home.productInfo.hairNail.description",
    link: "/shop",
    imgPath: IMG_PRODUCT_INFO_3,
  },
  {
    name: "products.kids.title",
    description: "pages.home.productInfo.kids.description",
    link: "/shop",
    imgPath: IMG_PRODUCT_INFO_4,
  },
];
