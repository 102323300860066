import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectCube, Pagination } from "swiper";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";
import IMG_LOGO from "assets/images/logo/logo-w-text.png";
import ICON_ARROW from "assets/icons/arrows/arrow-left-gray.svg";
import "./CheckoutHeader.scss";

const CheckoutHeader = ({ steps, currentStep }) => {
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const removeQueryParams = () => {
    const param = searchParams.get("step");
    if (param) {
      searchParams.delete("step");
      setSearchParams(searchParams);
    }
  };
  const handleGoBack = () => {
    const confirmAction = window.confirm("Are you sure you want to leave?");

    if (confirmAction) {
      removeQueryParams();
      navigate({
        pathname: "/",
        search: searchParams.toString(),
      });
    }
  };
  return (
    <div className="CheckoutHeader">
      <div className="HeaderLogoWrapper" onClick={handleGoBack}>
        <Swiper
          effect={"cube"}
          grabCursor={false}
          cubeEffect={{
            shadow: false,
            slideShadows: false,
          }}
          pagination={false}
          modules={[EffectCube, Autoplay, Pagination]}
          className="HeaderLogoSwiper"
          loop={true}
          speed={1000}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
        >
          <SwiperSlide>
            <img src={IMG_LOGO} alt="logo" className="HeaderLogo" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={IMG_LOGO} alt="logo" className="HeaderLogo" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={IMG_LOGO} alt="logo" className="HeaderLogo" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={IMG_LOGO} alt="logo" className="HeaderLogo" />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="Breadcrumb">
        <div>
          <span>{t("components.basket.cart")}</span>
        </div>
        {steps.map((step, index) => (
          <div key={index}>
            <img src={ICON_ARROW} alt={"arrow"} />
            <span className={currentStep === step ? "CurrentStep" : "Step"}>
              {t(`pages.checkout.${step}`)}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CheckoutHeader;

CheckoutHeader.propTypes = {
  steps: PropTypes.array,
  currentStep: PropTypes.string,
};
