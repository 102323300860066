export const HEADERS = {
  keys: {
    auth: "Authorization",
  },
  values: {
    authType: "Bearer",
  },
};

export const ENDPOINTS = {
  hello: "/hello",
  menu: "/menu",
  order: "/order",
};
