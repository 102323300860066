import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HOME_HERO_SLIDES } from "utils/constants/data/products";
import { ROUTE_NAME } from "utils/constants/routes";
import { getCSSName } from "pages/shop/components/shop-product-info/sub-components/ShopProductCard";
import ICON_ARROW_LEFT_LONG from "assets/icons/arrows/arrow-left-long.svg";
import "./HeroSlide.scss";

const HeroSlide = ({
  id,
  imgSrc,
  title,
  gradientColors,
  description,
  handleOnClick,
  productType,
}) => {
  const { t } = useTranslation();
  const getButtonStyle = (slideId) => ({
    borderImage:
      slideId === id
        ? `linear-gradient(to right, ${gradientColors.join(", ")}) 1`
        : "none",
  });

  const buttonBeforeStyle = {
    backgroundImage: `linear-gradient(to bottom, ${gradientColors.join(", ")})`,
  };

  return (
    <div className="HeroSlide">
      <div className="SlideContent">
        <h1
          className="SlideContentTitle"
          style={{
            backgroundImage: `linear-gradient(to right, ${gradientColors.join(
              ", "
            )})`,
          }}
        >
          {title}
        </h1>
        <div className="SeeMore">
          <Link
            to={`/${ROUTE_NAME.shop}`}
            className={`SeeMoreButton ${getCSSName(productType)}`}
          >
            <span style={buttonBeforeStyle} />
            <p>{t("buttons.more")}</p>
          </Link>
          <div className="Free">
            <span>{t("pages.home.hero.free")}</span>
            <img src={ICON_ARROW_LEFT_LONG} />
          </div>
        </div>
        <div className="SlideTitles">
          {HOME_HERO_SLIDES.map((slide) => (
            <button
              key={slide.id}
              className={slide.id === id ? "isActive" : ""}
              style={getButtonStyle(slide.id)}
              onClick={() => handleOnClick(slide.id - 1)}
            ></button>
          ))}
        </div>
        <p className="SlideDescription">{description}</p>
      </div>
      <div className="ImageContainer">
        <img src={imgSrc} alt={title} />
      </div>
    </div>
  );
};

export default HeroSlide;
HeroSlide.propTypes = {
  id: PropTypes.number.isRequired,
  imgSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  gradientColors: PropTypes.array.isRequired,
  description: PropTypes.string.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  productType: PropTypes.string.isRequired,
};
