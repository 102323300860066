import { INPUT_TYPES } from "components/custom-input/CustomInput";

export const inputs = {
  name: {
    label: "pages.checkout.inputs.name",
    type: INPUT_TYPES.text,
  },
  lastName: {
    label: "pages.checkout.inputs.lastName",
    type: INPUT_TYPES.text,
  },
  email: {
    label: "pages.checkout.inputs.email",
    type: INPUT_TYPES.email,
    helperText: "pages.checkout.inputs.invalidEmailText",
  },
  phone: {
    label: "pages.checkout.inputs.phone",
    type: INPUT_TYPES.phone,
    helperText: "pages.checkout.inputs.invalidPhoneText",
  },
  promoCode: {
    label: "pages.checkout.inputs.promoCode",
    type: INPUT_TYPES.text,
    helperText: "pages.checkout.inputs.invalidPromoCode",
  },
  city: {
    label: "pages.checkout.inputs.city",
    type: INPUT_TYPES.text,
  },
  addressLine1: {
    label: "pages.checkout.inputs.addressLine1",
    type: INPUT_TYPES.text,
  },
  addressLine2: {
    label: "pages.checkout.inputs.addressLine2",
    type: INPUT_TYPES.text,
  },
};
