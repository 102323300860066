import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getBankAuditEnabled } from "utils/envHelpers";
import useOutsideClick from "utils/hooks/useOutsideClick";
import { QUERY_PARAMS, ROUTE_NAME } from "utils/constants/routes";
import EmptyCart from "components/basket/sub-components/empty-cart/EmptyCart";
import Cart from "components/cart/Cart";
import CartReceipt from "components/cart-receipt/CartReceipt";
import { ReactComponent as IconBasket } from "assets/icons/basket/basket-new.svg";
import { ReactComponent as IconClose } from "assets/icons/close/close.svg";
import { ReactComponent as IconArrowRight } from "assets/icons/arrows/arrow-right.svg";
import "./Basket.scss";

const Basket = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isOpen, setIsOpen, catMenu } = useOutsideClick();
  const basketItems = useSelector((state) => state.basket.products);
  const { t } = useTranslation();
  const [isShown, setIsShown] = useState(false);
  const totalPrice = useSelector((state) => state.basket.total);
  const productsCount = basketItems.reduce((sum, product) => {
    return sum + product.count;
  }, 0);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflowX = "hidden";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const openBasketParam = searchParams.get(QUERY_PARAMS.openBasket);
    if (openBasketParam === "true") {
      setIsOpen(true);

      searchParams.delete(QUERY_PARAMS.openBasket);
      const updatedSearch = searchParams.toString();
      navigate({ search: updatedSearch }, { replace: true });
    }
  }, [location, navigate]);

  useEffect(() => {
    const isCheckoutPage = location.pathname.includes("/checkout");
    if (isCheckoutPage) {
      setIsShown(false);
    } else {
      setIsShown(true);
    }
  }, [location, navigate]);

  const handleGoToCheckout = () => {
    setIsOpen(false);
    navigate(ROUTE_NAME.checkout);
  };

  const handleGoToShopping = () => {
    setIsOpen(false);
    navigate(ROUTE_NAME.shop);
  };

  if (!getBankAuditEnabled()) {
    return (
      <>
        <div className={`Basket ${isOpen && "showBasket"}`}>
          <button
            className={isShown ? "showBasketButton" : "hideBasketButton"}
            onClick={() => setIsOpen(!isOpen)}
          >
            {productsCount > 0 && (
              <div className="ProductsCount">
                <span>{productsCount}</span>
              </div>
            )}
            <IconBasket />
          </button>
          <div
            className={`BasketContainer ${
              productsCount === 0 ? "isBasketEmpty" : ""
            }`}
            ref={catMenu}
          >
            <div className="BasketHeader">
              <h1>{t("components.basket.cart")}</h1>
              <button
                className="CloseBasketButton"
                onClick={() => setIsOpen(false)}
              >
                <IconClose />
              </button>
            </div>
            {productsCount === 0 ? (
              <div className="EmptyCartContainer">
                <EmptyCart />
                <button className="GoToShopping" onClick={handleGoToShopping}>
                  {t("buttons.goToShopping")}
                </button>
              </div>
            ) : (
              <>
                <Cart />
                <CartReceipt />
                <div>
                  <button className="GoToCheckout" onClick={handleGoToCheckout}>
                    <p>
                      {t("components.basket.total")} : $ {totalPrice}
                    </p>
                    <span>
                      CHECKOUT
                      <IconArrowRight />
                    </span>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        <div className={`BasketOverlay ${isOpen && "isVisible"}`} />
      </>
    );
  }
};

export default Basket;
