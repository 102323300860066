import axios from "axios";

export const getGeolocation = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("https://geolocation-db.com/json/")
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.error("Failed fetching geolocation data: ", err);
        reject(err);
      });
  });
};
