import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "./ProductBenefits.scss";

const ProductBenefits = ({ name, benefits }) => {
  const { t } = useTranslation();
  return (
    <section className="ProductBenefits">
      <div className="SectionContainer">
        <h3>
          {t("pages.shop.productInfo.benefitsOf")} {name}{" "}
        </h3>
      </div>
      <div className="BenefitsContainer">
        {benefits.map((benefit, index) => {
          return (
            <div key={index} className="Benefit">
              <div>
                <p className="BenefitTitle">{`${index + 1}. ${t(
                  benefit.title
                )}`}</p>
              </div>
              <img src={benefit.imgSrc} alt={t(benefit.title)} />
            </div>
          );
        })}
      </div>
    </section>
  );
};

ProductBenefits.propTypes = {
  name: PropTypes.string.isRequired,
  benefits: PropTypes.array.isRequired,
};
export default ProductBenefits;
