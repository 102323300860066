import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { addManyProducts } from "redux/slices/basketStore";
import { findProductById } from "utils/global";
import { PRODUCTS, COMBO_PRODUCTS } from "utils/constants/data/products";
import { QUERY_PARAMS } from "utils/constants/routes";
function useSharedLinkParams() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const productsParam = searchParams.get(QUERY_PARAMS.products);

  if (productsParam) {
    const productsArray = productsParam.split(",");

    const newBasket = productsArray.map((product) => {
      const [id, count] = product.split(":");
      const productData = findProductById(Number(id), PRODUCTS, COMBO_PRODUCTS);
      return { ...productData, count: Number(count) };
    });

    dispatch(addManyProducts(newBasket));

    searchParams.delete(QUERY_PARAMS.products);
    navigate({
      pathname: "/",
      search: searchParams.toString(),
    });
  }
}

export default useSharedLinkParams;
