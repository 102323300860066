const TRANSLATION = {
  buttons: {
    price: "Qiymət",
    shopNow: "İNDİ AL",
  },
  pages: {
    home: {
      name: "ANA SƏHİFƏ",
    },
  },
  components: {
    customSelect: {
      lang: "Dil",
      curr: "Valyuta",
      language: {
        en: "Ingilis",
        az: "Azərbaycan",
        ru: "Rus dili",
      },
      currency: {
        azn: "Azərbaycan manatı",
        usd: "Dollar",
        eur: "Evro",
      },
    },
  },
};

export default TRANSLATION;
