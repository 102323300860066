import ICON_FlAG_ENG from "assets/icons/languages/eng.png";
import ICON_FlAG_AZ from "assets/icons/languages/az.png";
import ICON_FlAG_RU from "assets/icons/languages/ru.svg";

export const SELECT_MENU = {
  language: [
    {
      value: "en",
      label: "Eng",
      opt: "components.customSelect.language.en",
      originalOpt: "English",
      icon: ICON_FlAG_ENG,
    },
    {
      value: "az",
      label: "AZ",
      opt: "components.customSelect.language.az",
      originalOpt: "Azərbaycan",
      icon: ICON_FlAG_AZ,
    },
    {
      value: "ru",
      label: "RU",
      opt: "components.customSelect.language.ru",
      originalOpt: "Русский",
      icon: ICON_FlAG_RU,
    },
  ],
  currency: [
    {
      value: "usd",
      label: "USD",
      opt: "USD",
      originalOpt: "components.customSelect.currency.usd",
      icon: "₼",
    },
    {
      value: "azn",
      label: "AZN",
      opt: "AZN",
      originalOpt: "components.customSelect.currency.azn",
      icon: "$",
    },
    {
      value: "eur",
      label: "EUR",
      opt: "EUR",
      originalOpt: "components.customSelect.currency.eur",
      icon: "€",
    },
  ],
};
