import { useRef, useState } from "react";

export default function useOutsideClick() {
  const [isOpen, setIsOpen] = useState(false);
  const catMenu = useRef(null);

  const closeOpenMenus = (e) => {
    if (catMenu.current && isOpen && !catMenu.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  document.addEventListener("mousedown", closeOpenMenus);

  return { isOpen: isOpen, setIsOpen: setIsOpen, catMenu };
}
