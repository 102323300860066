import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { addProduct } from "redux/slices/basketStore";
import { useDispatch } from "react-redux";
import { PRODUCTS } from "utils/constants/data/products";
import ProductDescription from "pages/shop/components/shop-product-info/sub-components/ProductDescription";
import ProductDetails from "pages/shop/components/shop-product-info/sub-components/ProductDetails";
import ProductBenefits from "pages/shop/components/shop-product-info/sub-components/ProductBenefits";
import IngredientsTable from "pages/shop/components/shop-product-info/sub-components/IngredientsTable";
import { PRODUCT_TYPE } from "utils/constants/data/shop-products";
import { getElementByID } from "utils/global";
import ICON_ARROW_DOWN from "assets/icons/arrows/product-info-arrow-down.svg";
import "./ShopProductCard.scss";

export const getCSSName = (type) => {
  switch (type) {
    case PRODUCT_TYPE.sleep: {
      return "isSleep";
    }
    case PRODUCT_TYPE.hair: {
      return "isHair";
    }
    case PRODUCT_TYPE.kids: {
      return "isKids";
    }
    case PRODUCT_TYPE.energy: {
      return "isEnergy";
    }
  }
};

const ShopProductCard = ({ product }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const addToCartBtnClassName = "AddToCartBtn";

  const handleAdd = (id) => {
    dispatch(addProduct(getElementByID(id, PRODUCTS)));
  };

  const handleCardClick = (event) => {
    // Check if the clicked element is not add to cart button
    if (!event.target.classList.contains(addToCartBtnClassName)) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <div
      className={`ShopProductCard ${getCSSName(product.productType)}`}
      onClick={handleCardClick}
    >
      <div className="SectionContainer">
        <div className="ShopProductInfoCard">
          <div>
            <img
              src={product.imgSrc}
              alt={t(product.name)}
              className="ProductImg"
            />
            <div>
              <div>
                <h3>{t(product.name)}</h3>
                <p className="Price">
                  {t("buttons.price")}: ${product.price}
                </p>
              </div>
              <button
                className={addToCartBtnClassName}
                onClick={() => {
                  handleAdd(product.id);
                }}
              >
                {t("buttons.addToCart")}
              </button>
            </div>
          </div>
          <div>
            <img
              src={ICON_ARROW_DOWN}
              alt="arrow"
              className={isOpen ? "RotateIcon ArrowIcon" : "ArrowIcon"}
            />
          </div>
        </div>
      </div>

      <div className={isOpen ? " ProductInfo" : "ProductInfo HideInfo"}>
        <>
          <ProductDetails
            subTitle={t(product.subTitle)}
            name={t(product.name)}
            infoImgSrc={product.infoImgSrc}
          />
          <ProductDescription
            imgSrc={product.description1.imgSrc}
            content={t(product.description1.content)}
            theme={getCSSName(product.productType)}
          />
          <ProductBenefits name={t(product.name)} benefits={product.benefits} />
          <ProductDescription
            imgSrc={product.description2.imgSrc}
            content={t(product.description2.content)}
            theme={getCSSName(product.productType)}
          />
          <IngredientsTable
            ingredients={product.Ingredients}
            theme={getCSSName(product.productType)}
            imgSrc={product.imgSrc}
            addToCartBtnClassName={addToCartBtnClassName}
            onAdd={() => {
              handleAdd(product.id);
            }}
          />
        </>
      </div>
    </div>
  );
};

export default ShopProductCard;

ShopProductCard.propTypes = {
  product: PropTypes.object.isRequired,
};
