import React from "react";
import { useTranslation } from "react-i18next";
import { LABELS } from "utils/constants/data/products";
import "./IngredientLabels.scss";

const IngredientLabels = () => {
  const { t } = useTranslation();
  return (
    <div className="SectionContainer">
      <section className="IngredientLabels">
        {LABELS.map((label) => {
          return (
            <div key={label.id}>
              <div className="LabelIcon">
                <img src={label.icon} alt={t(label.name)} />
              </div>
              <h3>{t(label.name)}</h3>
              <p>{t(label.description)}</p>
            </div>
          );
        })}
      </section>
    </div>
  );
};

export default IngredientLabels;
