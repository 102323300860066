export const handleCursor = (e) => {
    const currentCursorPosition = e.target.selectionStart;
    const dialCodeLength = 5;
    if (currentCursorPosition <= dialCodeLength && (e.keyCode === 8 || e.keyCode === 37)) { // 8 for backspace, 37 for left arrow
        e.preventDefault();
        e.target.selectionStart = dialCodeLength
        e.target.selectionEnd = dialCodeLength
    } else if (currentCursorPosition < dialCodeLength) {
        e.preventDefault();
        e.target.selectionStart = dialCodeLength
        e.target.selectionEnd = dialCodeLength
    }
};