import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import clipboardCopy from "clipboard-copy";
import CustomInput from "components/custom-input/CustomInput";
import { inputs } from "utils/constants/input";
import { QUERY_PARAMS } from "utils/constants/routes";
import { applyPromo, removePromo } from "redux/slices/basketStore";
import ICON_PROMO_CODE from "assets/icons/basket/promocode.svg";
import ICON_REMOVE_DISCOUNT from "assets/icons/close/close.svg";
import { ReactComponent as IconShare } from "assets/icons/basket/share.svg";
import { ReactComponent as IconChecked } from "assets/icons/basket/check.svg";
import "./CartReceipt.scss";

const CartReceipt = () => {
  const subtotal = useSelector((state) => state.basket.subtotal);
  const discount = useSelector((state) => state.basket.discount);
  const totalPrice = useSelector((state) => state.basket.total);
  const promo = useSelector((state) => state.basket.promoCode);
  const basketItems = useSelector((state) => state.basket.products);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [promoInputValue, setPromoInputValue] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [errors, setErrors] = useState({});

  const handleInputChange = (event) => {
    setPromoInputValue(event.target.value);
  };

  useEffect(() => {
    if (
      Object.keys(errors).length > 0 &&
      !Object.values(errors).find((error) => error)
    ) {
      dispatch(applyPromo(promoInputValue));
    }
  }, [errors]);

  const handleApplyClick = (event) => {
    event.preventDefault();
    setIsFormSubmitted(true);
    setTimeout(() => {
      setIsFormSubmitted(false);
    }, 0);
  };

  const removePromoCode = () => {
    dispatch(removePromo());
  };
  const copyToClipboard = () => {
    const queryParams = basketItems
      .map(({ id, count }) => `${id}:${count}`)
      .join(",");
    const url = new URL(window.location.href);

    clipboardCopy(
      `${url.host}?products=${queryParams}&${QUERY_PARAMS.openBasket}=true`
    )
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy URL to clipboard:", error);
      });
  };

  return (
    <div className="CartReceipt">
      <div className="PromoCode">
        <form onSubmit={(e) => handleApplyClick(e)}>
          <CustomInput
            label={t(inputs.promoCode.label)}
            type={inputs.promoCode.type}
            name="promo"
            id="promo"
            helperText={t(inputs.promoCode.helperText)}
            value={promoInputValue}
            onValueChange={handleInputChange}
            disabled={!!promo}
            isFormSubmitted={isFormSubmitted}
            setErrors={setErrors}
          />
          <button
            className={promo && "isDisabled"}
            type="submit"
            disabled={promo}
          >
            {t("buttons.apply")}
          </button>
        </form>
        {promo && (
          <div className="PromoTag">
            <img src={ICON_PROMO_CODE} alt="promo-tag" className="TagIcon" />
            <p>{promo.code}</p>
            <button onClick={removePromoCode}>
              <img src={ICON_REMOVE_DISCOUNT} alt="remove" className="" />
            </button>
          </div>
        )}
      </div>
      <div className="subtotal">
        <span>{t("components.basket.subtotal")} </span>
        <p>$ {subtotal}</p>
      </div>
      <div className="discount">
        <span>{t("components.basket.promoDiscount")}</span>
        <p>
          {promo ? promo.rate : 0}% (-$ {discount.toFixed(2)})
        </p>
      </div>
      <div className="totalPrice">
        <span>{t("components.basket.total")}</span>
        <p>$ {totalPrice}</p>
      </div>
      <div className="ShareButtonContainer">
        <button
          className={`ShareButton ${isCopied ? "Copied" : ""}`}
          onClick={copyToClipboard}
        >
          {isCopied ? (
            <>
              <IconChecked />
              <span>{t("buttons.copied")}</span>
            </>
          ) : (
            <>
              <IconShare />
              <span>{t("buttons.shareCart")}</span>
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default CartReceipt;
