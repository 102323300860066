import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { updateClientData, updateShipping } from "redux/slices/checkoutStore";
import CustomInput from "components/custom-input/CustomInput";
import { inputs } from "utils/constants/input";
import { QUERY_PARAMS } from "utils/constants/routes";
import { ReactComponent as IconArrowLeft } from "assets/icons/arrows/arrow-left-gray.svg";
import "./Information.scss";

const Information = ({ onNext }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const clientData = useSelector((state) => state.checkout.clientData);
  const shippingData = useSelector((state) => state.checkout.shippingData);
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [wasClicked, setWasClicked] = useState(() => {
    const storedValue = localStorage.getItem("wasClicked");
    return storedValue ? JSON.parse(storedValue) : false;
  });
  const removeQueryParams = () => {
    const param = searchParams.get("step");
    if (param) {
      searchParams.delete("step");
      setSearchParams(searchParams);
    }
  };
  const handleGoBack = () => {
    const confirmAction = window.confirm("Are you sure you want to leave?");

    if (confirmAction) {
      removeQueryParams();
      const searchParams = new URLSearchParams(location.search);
      searchParams.set(QUERY_PARAMS.openBasket, "true");
      // Navigates to the root URL with the openBasket parameter
      navigate({
        pathname: "/",
        search: searchParams.toString(),
      });
    }
  };

  useEffect(() => {
    if (
      Object.keys(errors).length > 0 &&
      !Object.values(errors).find((error) => error)
    ) {
      if (!wasClicked) {
        dispatch(
          updateShipping({ fieldName: "phone", value: clientData.phone })
        );
        dispatch(updateShipping({ fieldName: "name", value: clientData.name }));
        dispatch(
          updateShipping({ fieldName: "email", value: clientData.email })
        );
        dispatch(
          updateShipping({ fieldName: "lastName", value: clientData.lastName })
        );
        localStorage.setItem("wasClicked", JSON.stringify(true));
        setWasClicked(true);
      }
      onNext();
    }
  }, [errors]);

  const handleContinue = () => {
    setIsFormSubmitted(true);
    setTimeout(() => {
      setIsFormSubmitted(false);
    }, 0);
  };

  const handleInputChange = (e, field, inputValue = null) => {
    const value = inputValue === null ? e.target.value : inputValue;
    dispatch(updateClientData({ fieldName: field, value: value }));
  };

  const handleRadioChange = (e) => {
    dispatch(updateShipping({ fieldName: "method", value: e.target.value }));
  };

  return (
    <div className="CheckoutStep Information">
      <h3>
        {t("pages.checkout.about")}
        <span>*</span>
      </h3>
      <form className="AboutForm">
        <CustomInput
          label={t(inputs.name.label)}
          type={inputs.name.type}
          id="name"
          name="name"
          value={clientData.name}
          onValueChange={(e) => handleInputChange(e, "name")}
          required
          isFormSubmitted={isFormSubmitted}
          setErrors={setErrors}
        />
        <CustomInput
          label={t(inputs.lastName.label)}
          type={inputs.lastName.type}
          id="lastName"
          name="lastName"
          value={clientData.lastName}
          onValueChange={(e) => handleInputChange(e, "lastName")}
          required
          isFormSubmitted={isFormSubmitted}
          setErrors={setErrors}
        />

        <CustomInput
          label={t(inputs.phone.label)}
          type={inputs.phone.type}
          id="phone"
          name="phone"
          helperText={t(inputs.phone.helperText)}
          value={clientData.phone}
          onValueChange={(e, inputValue) =>
            handleInputChange(e, "phone", inputValue)
          }
          required
          isFormSubmitted={isFormSubmitted}
          setErrors={setErrors}
        />
        <CustomInput
          label={t(inputs.email.label)}
          type={inputs.email.type}
          id="email"
          name="email"
          value={clientData.email}
          helperText={t(inputs.email.helperText)}
          onValueChange={(e) => handleInputChange(e, "email")}
          isFormSubmitted={isFormSubmitted}
          setErrors={setErrors}
        />
      </form>

      <h3>{t("pages.checkout.shippingMethod")}</h3>
      <form className="ShippingMethodSelection">
        <div className="DeliveryMethod">
          <input
            type="radio"
            name="shipping"
            id="delivery"
            value="delivery"
            checked={shippingData.method === "delivery"}
            onChange={handleRadioChange}
          />
          <label htmlFor="delivery">
            <p>{t("pages.checkout.inputs.delivery")}</p>
            <div>
              <p className="ShippingPrice">{t("pages.checkout.free")}</p>
              <p className="ShippingTime">{t("pages.checkout.shippingTime")}</p>
            </div>
          </label>
        </div>
        <div className="PickUpMethod">
          <input
            type="radio"
            name="shipping"
            id="pickup"
            value="pickup"
            checked={shippingData.method === "pickup"}
            onChange={handleRadioChange}
          />
          <label htmlFor="pickup">
            <p>{t("pages.checkout.inputs.pickup")}</p>
            <div>
              <p className="ShippingPrice">{t("pages.checkout.free")}</p>
              <p className="ShippingTime">{t("pages.checkout.pickupTime")}</p>
            </div>
          </label>
        </div>
      </form>

      <div className="CheckoutButtons">
        <button className="ReturnButton" onClick={handleGoBack}>
          <IconArrowLeft /> {t("buttons.returnBack")}
        </button>
        <button className="ContinueButton" onClick={handleContinue}>
          {t("buttons.continue")}
        </button>
      </div>
    </div>
  );
};

export default Information;

Information.propTypes = {
  onNext: PropTypes.func.isRequired,
  onGoBack: PropTypes.func,
};
