import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ROUTE_NAME } from "utils/constants/routes";
import { getBankAuditEnabled } from "utils/envHelpers";
import { useTranslation } from "react-i18next";
import useOutsideClick from "utils/hooks/useOutsideClick";
import useWindowSize from "utils/hooks/useWindowSize";
import { getTabletBreakpoint } from "utils/global";
import { useURLMatch } from "utils/hooks/useURLMatch";
import ActionButton from "components/action-button/ActionButton";
import FooterModal from "components/footer/sub-components/FooterModal";
import Logo from "assets/images/logo/logo.svg";
import "./Footer.scss";

const TABLET_SIZE = getTabletBreakpoint();
const Footer = () => {
  const location = useLocation();
  const currentYear = new Date().getFullYear();
  const { t } = useTranslation();
  const [policyType, setPolicyType] = useState(null);
  const isURLMatch = useURLMatch(ROUTE_NAME.checkout);
  const {
    isOpen: isModalOpen,
    setIsOpen: setIsModalOpen,
    catMenu: modalRef,
  } = useOutsideClick();
  const {
    size: { width },
  } = useWindowSize();

  const handleButtonClick = (buttonId) => {
    setIsModalOpen(true);
    setPolicyType(buttonId);
  };

  if (isURLMatch) {
    return;
  }

  return (
    <footer className="Footer">
      <div className="SectionContainer">
        <div className="FooterContainer">
          <div className="FooterColumn">
            <div className="FooterCtaWrapper">
              <h2>{t("components.footer.quickActions")}</h2>
              <ul>
                <li>
                  <ActionButton type="WHATSAPP" />
                </li>
                <li>
                  <ActionButton type="INSTAGRAM" />
                </li>
                <li>
                  <ActionButton type="MOBILE" />
                </li>
                <li>
                  <ActionButton type="EMAIL" />
                </li>
              </ul>
            </div>
            {width > TABLET_SIZE && (
              <div className="LinksWrapper">
                <div className="PolicyLinks">
                  <h2>{t("components.footer.policyLinks.title")}</h2>
                  <ul>
                    <li>
                      <button onClick={() => handleButtonClick("terms")}>
                        {t("components.footer.policyLinks.termsAndConditions")}
                      </button>
                    </li>
                    <li>
                      <button onClick={() => handleButtonClick("privacy")}>
                        {t("components.footer.policyLinks.privacyPolicy")}
                      </button>
                    </li>
                    <li>
                      <button onClick={() => handleButtonClick("return")}>
                        {t("components.footer.policyLinks.returnPolicy")}
                      </button>
                    </li>
                  </ul>
                </div>
                {!getBankAuditEnabled() && (
                  <div className="FooterNavLinks">
                    <h2>{t("components.footer.links")}</h2>
                    <ul>
                      <li className={location.pathname === "/" ? "active" : ""}>
                        <Link to="/">
                          <p>{t("pages.home.name")}</p>
                        </Link>
                      </li>
                      <li
                        className={
                          location.pathname === "/shop" ? "active" : ""
                        }
                      >
                        <Link to={ROUTE_NAME.shop}>
                          <p>{t("pages.shop.name")}</p>
                        </Link>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>
          {width < TABLET_SIZE && (
            <div className="PolicyLinks">
              <h2>{t("components.footer.policyLinks.title")}</h2>
              <ul>
                <li>
                  <button onClick={() => handleButtonClick("terms")}>
                    {t("components.footer.policyLinks.termsAndConditions")}
                  </button>
                </li>
                <li>
                  <button onClick={() => handleButtonClick("privacy")}>
                    {t("components.footer.policyLinks.privacyPolicy")}
                  </button>
                </li>
                <li>
                  <button onClick={() => handleButtonClick("return")}>
                    {t("components.footer.policyLinks.returnPolicy")}
                  </button>
                </li>
              </ul>
            </div>
          )}
          {width < TABLET_SIZE && !getBankAuditEnabled() && (
            <div className="FooterNavLinks">
              <h2>{t("components.footer.links")}</h2>
              <ul>
                <li className={location.pathname === "/" ? "active" : ""}>
                  <Link to="/">{t("pages.home.name")}</Link>
                </li>
                <li className={location.pathname === "/shop" ? "active" : ""}>
                  <Link to={ROUTE_NAME.shop}>{t("pages.shop.name")}</Link>
                </li>
              </ul>
            </div>
          )}
          <div className="FooterLocations">
            <h2>{t("components.footer.pickupLocations.title")}</h2>
            <ul>
              <li>
                <a
                  href="https://goo.gl/maps/muE9b7ei8DYwAbiq6?coh=178571&entry=tt"
                  target="_blank"
                  rel="noreferrer"
                >
                  <h3>
                    {t("components.footer.pickupLocations.location1.title")}
                  </h3>
                  <p className="street">
                    <b>{t("components.footer.street")}:</b>{" "}
                    {t("components.footer.pickupLocations.location1.street")}
                  </p>
                  <p>
                    <b>{t("components.footer.zip")}:</b>{" "}
                    {t("components.footer.pickupLocations.location1.zip")}
                  </p>
                  <p>
                    <b>{t("components.footer.city")}:</b>{" "}
                    {t("components.footer.pickupLocations.location1.city")}
                  </p>
                </a>
              </li>
              <li>
                <a
                  href="https://goo.gl/maps/muE9b7ei8DYwAbiq6?coh=178571&entry=tt"
                  target="_blank"
                  rel="noreferrer"
                >
                  <h3>
                    {t("components.footer.pickupLocations.location2.title")}
                  </h3>
                  <p className="street">
                    <b>{t("components.footer.street")}:</b>{" "}
                    {t("components.footer.pickupLocations.location2.street")}
                  </p>
                  <p>
                    <b>{t("components.footer.zip")}:</b>{" "}
                    {t("components.footer.pickupLocations.location2.zip")}
                  </p>
                  <p>
                    <b>{t("components.footer.city")}:</b>{" "}
                    {t("components.footer.pickupLocations.location2.city")}
                  </p>
                </a>
              </li>
              <li>
                <a
                  href="https://goo.gl/maps/muE9b7ei8DYwAbiq6?coh=178571&entry=tt"
                  target="_blank"
                  rel="noreferrer"
                >
                  <h3>
                    {t("components.footer.pickupLocations.location3.title")}
                  </h3>
                  <p className="street">
                    <b>{t("components.footer.street")}:</b>{" "}
                    {t("components.footer.pickupLocations.location3.street")}
                  </p>
                  <p>
                    <b>{t("components.footer.zip")}:</b>{" "}
                    {t("components.footer.pickupLocations.location3.zip")}
                  </p>
                  <p>
                    <b>{t("components.footer.city")}:</b>{" "}
                    {t("components.footer.pickupLocations.location3.city")}
                  </p>
                </a>
              </li>
            </ul>
          </div>
          <div className="FooterContacts">
            <h2>{t("components.footer.contacts")}</h2>
            <ul>
              <li>
                <a
                  href="mailto:hello@gia-vitamins.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <h3> {t("components.footer.email")} </h3>
                  <p>hello@gia-vitamins.com</p>
                </a>
              </li>
              <li>
                <a href="tel:+994552090072" target="_blank" rel="noreferrer">
                  <h3> {t("components.footer.phone")} </h3>
                  <p>+994(55) 209 00 72</p>
                </a>
              </li>
              <li>
                <a
                  href="https://goo.gl/maps/muE9b7ei8DYwAbiq6?coh=178571&entry=tt"
                  target="_blank"
                  rel="noreferrer"
                >
                  <h3> {t("components.footer.addresses.title")} </h3>
                  <p>
                    {t("components.footer.addresses.address1.title")} <br />
                    {t("components.footer.addresses.address1.addressLine")}
                  </p>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="FooterInfoContainer">
          <div className="FooterLogo">
            <img src={Logo} alt="logo" />
          </div>
          <p className="Copyright">
            &copy; {currentYear} {t("components.footer.copyRights")}
          </p>
          <div className="Credit">
            {t("components.footer.madeBy")}
            <a
              href="https://www.instagram.com/g22mediagency/"
              target="_blank"
              rel="noreferrer"
            >
              G22
            </a>
            &nbsp;/&nbsp;
            <a
              href="https://www.instagram.com/onezero_group/"
              target="_blank"
              rel="noreferrer"
            >
              One Zero
            </a>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <FooterModal
          policyType={policyType}
          onClose={() => setIsModalOpen(false)}
          modalRef={modalRef}
        />
      )}
    </footer>
  );
};

export default Footer;
