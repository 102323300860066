import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import AppRouter from "pages/AppRouter";
import Footer from "components/footer/Footer";
import Header from "components/header/Header";
import Basket from "components/basket/Basket";
import LoadingProduct from "components/loading-product/LoadingProduct";
import QuickActionsBar from "components/quick-actions-bar/QuickActionsBar";
import { REDUX_STORE } from "redux/store";
import "./App.scss";

function App() {
  const [isAppLoading, setIsAppLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAppLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (isAppLoading) {
    return <LoadingProduct />;
  }

  return (
    <>
      <Provider store={REDUX_STORE}>
        <BrowserRouter>
          <Header />
          <AppRouter />
          <Basket />
          <Footer />
          <QuickActionsBar />
        </BrowserRouter>
      </Provider>
    </>
  );
}

export default App;
