import { useDispatch, useSelector } from "react-redux";
import { resetBasket } from "redux/slices/basketStore";
import { resetCheckoutStore } from "redux/slices/checkoutStore";
import {
  resetAppStore,
  setDarkMode,
  setUserGeneratedId,
  setUserGeoLocation,
  updateAppVersion,
  updateReduxVersion,
} from "redux/slices/appStore";

function useAppVersioning() {
  const dispatch = useDispatch();
  const currentReduxVersion = useSelector((state) => state.app.reduxVersion);
  const newReduxVersion = parseInt(process.env.REACT_APP_REDUX_VERSION);
  const userGenerateId = useSelector((state) => state.app.userGeneratedId);
  const userGeolocation = useSelector((state) => state.app.userGeolocation);
  const appVersion = useSelector((state) => state.app.appVersion);
  const isDarkMode = useSelector((state) => state.app.isDarkMode);

  if (
    newReduxVersion &&
    (!currentReduxVersion || newReduxVersion > currentReduxVersion)
  ) {
    dispatch(resetBasket());
    dispatch(resetCheckoutStore());
    dispatch(resetAppStore());

    dispatch(setUserGeoLocation(userGeolocation));
    dispatch(setUserGeneratedId(userGenerateId));
    dispatch(setDarkMode(isDarkMode));
    dispatch(updateAppVersion(appVersion));
    dispatch(updateReduxVersion(newReduxVersion));
    localStorage.removeItem("wasClicked");
  }
}

export default useAppVersioning;
