import { compose, configureStore } from "@reduxjs/toolkit";
import persistState from "redux-localstorage";

import appReducer from "redux/slices/appStore";
import basketReducer from "redux/slices/basketStore";
import checkoutReducer from "redux/slices/checkoutStore";

import { STORE_NAMES } from "utils/constants/redux";

const enhancerLocalStorage = compose(persistState(/*paths, config*/));

export const REDUX_STORE = configureStore({
  reducer: {
    [STORE_NAMES.APP]: appReducer,
    [STORE_NAMES.BASKET]: basketReducer,
    [STORE_NAMES.CHECKOUT]: checkoutReducer,
  },
  enhancers: (defaultEnhancers) => [...defaultEnhancers, enhancerLocalStorage],
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: {
          arg1: "arg1 dummy",
          arg2: "arg2 dummy",
        },
      },
    }),
});
