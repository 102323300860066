import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "./BuyProductSlide.scss";

const BuyProductSlide = ({ imgSrc, title, price, onAdd }) => {
  const { t } = useTranslation();
  return (
    <div className="BuyProductSlide">
      <img src={imgSrc} alt="slide-product" />
      <div className="BuyProductSlideDescription">
        <h3>{title}</h3>
        <p> $ {price}</p>
      </div>
      <button className="addToCart" onClick={onAdd}>
        {t("buttons.addToCart")}
      </button>
    </div>
  );
};

BuyProductSlide.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  onAdd: PropTypes.func.isRequired,
};

export default BuyProductSlide;
