import React, { useEffect } from "react";
import ShopProductInfo from "pages/shop/components/shop-product-info/ShopProductInfo";
import SpecialOffers from "components/special-offers/SpecialOffers";
import RiskFree from "components/risk-free/RiskFree";
import ShippingPaymentMethods from "components/shipping-payment-methods/ShippingPaymentMethods";
import Reviews from "components/reviews/Reviews";

const Shop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <ShopProductInfo />
      <SpecialOffers />
      <RiskFree />
      <ShippingPaymentMethods />
      <Reviews />
    </>
  );
};

export default Shop;
