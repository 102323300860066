import { createSlice } from "@reduxjs/toolkit";
import { STORE_NAMES } from "utils/constants/redux";

const initialState = {
  value: 0,
  isDarkMode: false,
  userGeolocation: null,
  userGeneratedId: null,
  appVersion: 0,
  reduxVersion: 0,
};

export const appStore = createSlice({
  name: STORE_NAMES.APP,
  initialState: initialState,
  reducers: {
    setUserGeoLocation: (state, action) => {
      const userGeolocation = action.payload;
      return { ...state, userGeolocation };
    },
    setUserGeneratedId: (state, action) => {
      const userGeneratedId = action.payload;
      return { ...state, userGeneratedId };
    },
    setDarkMode: (state, action) => {
      const isDarkMode = action.payload;
      return { ...state, isDarkMode };
    },
    updateAppVersion: (state, action) => {
      const appVersion = action.payload;
      return { ...state, appVersion };
    },
    increment: (state) => {
      const value = state.value++;
      return { ...state, value };
    },
    decrement: (state) => {
      const value = state.value--;
      return { ...state, value };
    },
    incrementByAmount: (state, action) => {
      const amount = action.payload;
      const value = state.value + amount;
      return { ...state, value };
    },
    updateReduxVersion: (state, action) => {
      const reduxVersion = action.payload;
      return { ...state, reduxVersion };
    },
    resetAppStore: () => {
      return { ...initialState };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setUserGeoLocation,
  setUserGeneratedId,
  setDarkMode,
  increment,
  decrement,
  incrementByAmount,
  updateAppVersion,
  updateReduxVersion,
  resetAppStore,
} = appStore.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const incrementThunk = (amount) => (dispatch, getState, myArgs) => {
  setTimeout(() => {
    console.log("amount ", amount);
    console.log("getState ", getState);
    console.log("myArgs ", myArgs);

    dispatch(incrementByAmount(amount + 1));
    setTimeout(() => {
      dispatch(decrement());
      dispatch(decrement());
    }, 1000);
  }, 1000);
};

export default appStore.reducer;
