import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import FooterModal from "components/footer/sub-components/FooterModal";
import useOutsideClick from "utils/hooks/useOutsideClick";
import "./CheckoutFooter.scss";

const CheckoutFooter = () => {
  const { t } = useTranslation();
  const [policyType, setPolicyType] = useState(null);
  const {
    isOpen: isModalOpen,
    setIsOpen: setIsModalOpen,
    catMenu: modalRef,
  } = useOutsideClick();

  const handleButtonClick = (buttonId) => {
    setIsModalOpen(true);
    setPolicyType(buttonId);
  };

  return (
    <section className="CheckoutFooter SectionContainer">
      <p className="GuaranteeInfo">{t("pages.checkout.guaranteeInfo")}</p>
      <ul className="PolicyLinks">
        <li>
          <button onClick={() => handleButtonClick("terms")}>
            <p>{t("components.footer.policyLinks.termsAndConditions")}</p>
          </button>
        </li>
        <li>
          <button onClick={() => handleButtonClick("privacy")}>
            <p>{t("components.footer.policyLinks.privacyPolicy")}</p>
          </button>
        </li>
        <li>
          <button onClick={() => handleButtonClick("return")}>
            <p>{t("components.footer.policyLinks.returnPolicy")}</p>
          </button>
        </li>
      </ul>
      {isModalOpen && (
        <FooterModal
          policyType={policyType}
          onClose={() => setIsModalOpen(false)}
          modalRef={modalRef}
        />
      )}
    </section>
  );
};

export default CheckoutFooter;
