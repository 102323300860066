import React from "react";
import Confetti from "react-confetti";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Modal from "pages/checkout/components/checkout-steps/sub-components/modal/Modal";
import IMAGE_SUCCESS from "assets/images/payment/payment-success.svg";
import "./PaymentSuccess.scss";

const PaymentSuccess = ({ handleAccept }) => {
  const totalPrice = useSelector((state) => state.basket.total);
  const { t } = useTranslation();

  return (
    <Modal buttonName={t("buttons.accept")} handlePayment={handleAccept}>
      <Confetti />
      <div className="PaymentSuccessModal">
        <div className="PaymentModalImage">
          <img src={IMAGE_SUCCESS} alt="success" />
        </div>
        <div className="PaymentModalContent">
          <div className="PaymentModalContentTop">
            <h6 className="PaymentModalContentTopTitle">
              {t("pages.checkout.paymentSuccess")}
            </h6>
            <span className="PaymentModalContentTopSubTitle">
              {t("pages.checkout.received") + " "}
              <span className="PaymentModalContentPrice">
                ${totalPrice}
              </span>{" "}
              payment
            </span>
          </div>
          <div className="PaymentModalContentBottom">
            <h6 className="PaymentModalContentBottomTitle">
              {t("pages.checkout.paymentDetails")}
            </h6>
            <ul className="PaymentModalContentBottomDetails">
              <li>
                <span className="PaymentDetailsKey">
                  {t("pages.checkout.paymentDate")}
                </span>
                <span className="PaymentDetailsValue">25.05.2023, 13:10</span>
              </li>
              <li>
                <span className="PaymentDetailsKey">
                  {t("pages.checkout.paidBy")}
                </span>
                <span className="PaymentDetailsValue">416911******2233</span>
              </li>
              <li>
                <span className="PaymentDetailsKey">
                  {t("pages.checkout.paymentAmount")}
                </span>
                <span className="PaymentDetailsValue">${totalPrice}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Modal>
  );
};

PaymentSuccess.propTypes = {
  handleAccept: PropTypes.func.isRequired,
};

export default PaymentSuccess;
