import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "./ProductInfoCard.scss";

const ProductInfoCard = ({ imgSrc, description, linkTo }) => {
  const { t } = useTranslation();

  return (
    <div className="ProductInfoCard">
      <img src={imgSrc} alt="product-info" />
      <p className="ProductInfoDescription">{description}...</p>
      <Link to={linkTo} className="ProductInfoCardLinkButton">
        {t("buttons.learnMore")}
      </Link>
    </div>
  );
};

export default ProductInfoCard;

ProductInfoCard.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
};
