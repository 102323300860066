import React from "react";
import PropTypes from "prop-types";
import "./ProductDescription.scss";

const ProductDescription = ({ imgSrc, content, theme }) => {
  return (
    <div className="ProductDescription">
      <img src={imgSrc} alt="description img" />
      <div className={`Description ${theme}`}>
        <p>{content}</p>
      </div>
    </div>
  );
};

export default ProductDescription;

ProductDescription.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  theme: PropTypes.string,
};
