import { INPUT_NAMES, INPUT_TYPES } from "components/custom-input/CustomInput";
import { PROMO_CODES } from "utils/constants/data/promo-codes";

export const validateInput = (value, required, type, name, isChanged) => {
  let isValid = true;
  const isPhoneInput = type === INPUT_TYPES.phone;
  let regex;
  if (
    required &&
    (isPhoneInput ? value === "+994 __ ___ __ __" : value === "")
  ) {
    isValid = false;
  }
  if (type === INPUT_TYPES.email && value !== "") {
    regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    isValid = regex.test(value);
  }
  if (isPhoneInput) {
    isValid = /^\+994 (10|50|51|55|60|70|77|99) \d{3} \d{2} \d{2}$/.test(value);
  }
  if (name === INPUT_NAMES.promo) {
    isValid = false;
    for (const promo of PROMO_CODES) {
      if (promo.code === value.trim().toUpperCase()) {
        isValid = true;
        break;
      }
    }
  }
  if (isChanged && !isValid) return true;
  return isValid;
};
