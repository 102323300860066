import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { POLICIES } from "utils/constants/data/polices";
import { useLockBodyScroll } from "utils/hooks/useLockBodyScroll";
import { ReactComponent as IconClose } from "assets/icons/close/close.svg";
import "./FooterModal.scss";

const FooterModal = ({ onClose, modalRef, policyType }) => {
  const { t } = useTranslation();
  useLockBodyScroll();

  return (
    <div className="FooterModal">
      <div className="FooterModalContent" ref={modalRef}>
        <div className="FooterModalContentHeader">
          <h3 className="TextColorSunset"> {t(POLICIES[policyType].title)}</h3>
          <button className="FooterModalClose" onClick={onClose}>
            <IconClose />
          </button>
        </div>
        <div className="FooterModalContentBody">
          <p>{t(POLICIES[policyType].header)}</p>
          {POLICIES[policyType].body.map((policy, index) => (
            <div key={index}>
              <h3>{t(policy.heading)}</h3>
              <p>{t(policy.content)}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

FooterModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  modalRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  policyType: PropTypes.oneOf(["terms", "privacy", "return"]).isRequired,
};

export default FooterModal;
