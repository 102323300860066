import { useEffect } from "react";
import { useSelector } from "react-redux";
import { STORE_NAMES } from "utils/constants/redux";

const useCssRootVars = () => {
  const reduxApp = useSelector((state) => state[STORE_NAMES.APP]);

  const initRootVars = (root) => {
    root.style.setProperty("--mouse-x", 0 + "px");
    root.style.setProperty("--mouse-y", 0 + "px");
    root.style.setProperty("--scroll-body", 0);
  };

  useEffect(() => {
    const root = document.documentElement;
    if (reduxApp.isDarkMode) {
      root.setAttribute("data-theme", "dark");
    } else {
      root.setAttribute("data-theme", "light");
    }
  }, [reduxApp.isDarkMode]);

  //track mouse X,Y coordinates
  useEffect(() => {
    let root = document.documentElement;

    initRootVars(root);

    const handleMouseMove = (event) => {
      const x = event.clientX;
      const y = event.clientY;
      root.style.setProperty("--mouse-x", x + "px");
      root.style.setProperty("--mouse-y", y + "px");
    };

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const fullHeight = document.body.clientHeight;
      const scrollPercentage = (
        (scrollPosition / (fullHeight - windowHeight)) *
        100
      ).toFixed(3);
      root.style.setProperty("--scroll-body", scrollPercentage);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);
};

export default useCssRootVars;
