import React, { forwardRef, useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import PropTypes from "prop-types";
import { handleCursor } from "utils/cursor-moving";
import { validateInput } from "utils/input-validate";
import { PHONE } from "utils/constants/global";
import ICON_ERROR from "assets/icons/error/inpur-error.svg";
import "./CustomInput.scss";

export const INPUT_TYPES = {
  text: "text",
  email: "email",
  phone: "tel",
};

export const INPUT_NAMES = {
  promo: "promo",
};

// eslint-disable-next-line
const CustomInput = forwardRef(
  (
    {
      label,
      type,
      id,
      name,
      helperText,
      value,
      onValueChange,
      disabled = false,
      required,
      isFormSubmitted,
      setErrors,
    },
    ref
  ) => {
    const isPhoneInput = type === INPUT_TYPES.phone;
    const [isFocused, setIsFocused] = useState(false);
    const [isPlaceholderShown, setIsPlaceholderShown] = useState(
      isPhoneInput
        ? value === PHONE.emptyPhoneValue ||
            value === PHONE.emptyPhoneValueWithDialCode ||
            value === ""
        : value === ""
    );
    const [inputError, setInputError] = useState(false);
    const { control } = useForm();

    const handleFocus = (event) => {
      setIsFocused(true);
      if (!value && isPhoneInput) {
        handleInputChange(event);
        onValueChange(event, PHONE.dialCode);
      }
      setInputError(false);
    };

    useEffect(() => {
      if (isFormSubmitted) {
        const inputValidation = !validateInput(value, required, type, name);
        setErrors((prevErrors) => ({ ...prevErrors, [name]: inputValidation }));
        setInputError(inputValidation);
      }
    }, [isFormSubmitted]);

    const handleBlur = (event) => {
      const value = event.target.value;
      const inputType = event.target.type;
      const required = event.target.required;
      setIsFocused(false);
      if (
        (value === PHONE.dialCode ||
          value === PHONE.emptyPhoneValueWithDialCode ||
          value === "") &&
        isPhoneInput
      ) {
        handleInputChange(event);
        onValueChange(event, "");
      }
      setInputError(!validateInput(value, required, inputType, name));
    };

    const handleInputChange = async (event) => {
      let value = event.target.value;
      setIsPlaceholderShown(
        isPhoneInput
          ? value === PHONE.emptyPhoneValue ||
              value === PHONE.emptyPhoneValueWithDialCode ||
              value === ""
          : value === ""
      );
      const inputType = event.target.type;
      const required = event.target.required;
      if (isPhoneInput && value === PHONE.emptyPhoneValue) return;
      setInputError(!validateInput(value, required, inputType, name, true));
      onValueChange(event);
    };

    const inputProps = {
      style: {
        color: "#1E1E1E",
        backgroundColor: "#fbfbfb",
        borderRadius: "5px",
        fontFamily: '"Inter", sans-serif',
      },
      inputProps: {
        style: {
          lineHeight: "17px",
          padding: "14px 11px",
          height: "unset",
        },
      },
      onFocus: handleFocus,
      onBlur: handleBlur,
    };

    const inputLabelProps = {
      style: {
        color: inputError ? "#d32f2f" : "#A7A7A7",
        transform:
          !isPlaceholderShown || isFocused
            ? "translate(14px, -9px) scale(0.75)"
            : "translate(11px, 12px) scale(1)",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "17px",
      },
    };

    const textInputProps = {
      className: "CustomInput",
      variant: "outlined",
      fullWidth: true,
      InputProps: inputProps,
      InputLabelProps: inputLabelProps,
      onChange: handleInputChange,
      value: value,
      error: inputError,
      disabled: disabled,
      label: label,
      name: name,
      id: id,
      type: type,
      inputRef: ref,
      helperText:
        value === "" ? (
          ""
        ) : inputError && helperText ? (
          <>
            <img src={ICON_ERROR} alt="error" />
            <span> {helperText}</span>
          </>
        ) : (
          ""
        ),
    };

    if (required) {
      textInputProps.required = true;
    }

    return (
      <>
        {isPhoneInput ? (
          <Controller
            name="phone"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                {...textInputProps}
                InputProps={{
                  ...inputProps,
                  inputComponent: CustomInputMask,
                  inputProps: {
                    mask: "+994 99 999 99 99",
                    ...inputProps.inputProps,
                  },
                  onKeyDown: handleCursor,
                  onClick: handleCursor,
                }}
              />
            )}
          />
        ) : (
          <TextField {...textInputProps} />
        )}
      </>
    );
  }
);

// eslint-disable-next-line
const CustomInputMask = forwardRef((props, ref) => {
  const { inputRef, ...otherProps } = props;
  return <InputMask ref={inputRef} {...otherProps} />;
});

CustomInputMask.propTypes = {
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};
CustomInput.displayName = "CustomInput";
CustomInputMask.displayName = "CustomInputMask";

CustomInput.propTypes = {
  type: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  value: PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  isFormSubmitted: PropTypes.bool.isRequired,
  setErrors: PropTypes.func.isRequired,
};

export default CustomInput;
