import React from "react";
import { useTranslation } from "react-i18next";
import ICON_EMPTY_CART from "assets/icons/basket/EmptyCart.svg";
import "./EmptyCart.scss";

const EmptyCart = () => {
  const { t } = useTranslation();
  return (
    <div className="EmptyCart">
      <img src={ICON_EMPTY_CART} alt="empty" />
      <h3>{t("components.basket.emptyCart")} </h3>
      <p>{t("components.basket.emptyCartContent")}</p>
    </div>
  );
};

export default EmptyCart;
