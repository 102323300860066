import { createSlice } from "@reduxjs/toolkit";
import { STORE_NAMES } from "utils/constants/redux";
import { PROMO_CODES } from "utils/constants/data/promo-codes";

const calculatePrices = (products, promoCode) => {
  const subtotal = products.reduce(
    (acc, product) => acc + product.price * product.count,
    0
  );
  const discount = promoCode ? (subtotal * promoCode.rate) / 100 : 0;
  const total = subtotal - discount;

  return {
    subtotal,
    discount,
    total,
  };
};

const INITIAL_STATE = {
  products: [],
  promoCode: null,
  subtotal: 0,
  discount: null,
  shipping: null,
  total: 0,
};

export const basketStore = createSlice({
  name: STORE_NAMES.BASKET,
  initialState: INITIAL_STATE,
  reducers: {
    addProduct: (state, action) => {
      const productToAdd = action.payload;
      const existingProduct = state.products.find(
        (product) => product.id === productToAdd.id
      );
      let products = [];

      if (existingProduct) {
        const productToUpdate = { ...existingProduct };
        productToUpdate.count++;
        products = state.products.map((product) =>
          product.id === existingProduct.id ? productToUpdate : product
        );
      } else {
        products = [...state.products, { ...productToAdd, count: 1 }];
      }

      const priceDetails = calculatePrices(products, state.promoCode);

      return {
        ...state,
        ...priceDetails,
        products,
      };
    },

    removeProduct: (state, action) => {
      const id = action.payload;
      let promoCode = state.promoCode;
      let products = state.products.filter((product) => product.id !== id);

      if (products.length === 0) {
        promoCode = null;
      }

      const priceDetails = calculatePrices(products, promoCode);

      return {
        ...state,
        ...priceDetails,
        promoCode,
        products,
      };
    },

    decreaseProductCount: (state, action) => {
      const id = action.payload;
      let promoCode = state.promoCode;
      const targetProduct = state.products.find((product) => product.id === id);
      let products = [];

      if (targetProduct) {
        if (targetProduct.count > 1) {
          const productToUpdate = { ...targetProduct };
          productToUpdate.count--;
          products = state.products.map((product) =>
            productToUpdate.id === product.id ? productToUpdate : product
          );
        } else {
          products = state.products.filter((product) => product.id !== id);
        }

        if (products.length === 0) {
          promoCode = null;
        }

        const priceDetails = calculatePrices(products, promoCode);

        return {
          ...state,
          ...priceDetails,
          promoCode,
          products,
        };
      }
    },

    applyPromo: (state, action) => {
      const code = action.payload.trim().toUpperCase();
      let promoCode = null;

      for (const promo of PROMO_CODES) {
        if (promo.code === code) {
          promoCode = promo;
          break;
        }
      }
      const priceDetails = calculatePrices(state.products, promoCode);
      return {
        ...state,
        ...priceDetails,
        promoCode,
      };
    },

    removePromo: (state) => {
      const priceDetails = calculatePrices(state.products, null);
      return {
        ...state,
        ...priceDetails,
        promoCode: null,
      };
    },

    addManyProducts: (state, action) => {
      const products = action.payload;
      const priceDetails = calculatePrices(products, state.promoCode);
      return { ...state, ...priceDetails, products };
    },

    resetBasket: () => {
      return { ...INITIAL_STATE };
    },
  },
});

export const {
  addProduct,
  removeProduct,
  decreaseProductCount,
  applyPromo,
  removePromo,
  addManyProducts,
  resetBasket,
} = basketStore.actions;

export default basketStore.reducer;
