export const LANGUAGES = {
  en: {
    name: "English",
    code: "EN",
  },
  ru: {
    name: "Русский",
    code: "RU",
  },
  az: {
    name: "Azərbaycanca",
    code: "AZ",
  },
};
