import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper";
import { useTranslation } from "react-i18next";
import HeroSlide from "pages/home/components/hero/sub-components/HeroSlide";
import { HOME_HERO_SLIDES } from "utils/constants/data/products";
import "./Hero.scss";

SwiperCore.use([Pagination]);

const Hero = () => {
  const swiperRef = useRef(null);
  const { t } = useTranslation();

  const handleOnClick = (index) => {
    // Disable autoplay
    swiperRef.current.swiper.autoplay.stop();

    // Change the slide
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(index);
    }

    // Enable autoplay again after the slide change is complete
    setTimeout(() => {
      swiperRef.current.swiper.autoplay.start();
    }, 100);
  };

  return (
    <section id="HeroSection">
      <Swiper
        pagination={{ clickable: true }}
        ref={swiperRef}
        spaceBetween={30}
        modules={[Autoplay, EffectFade, Navigation]}
        effect={"fade"}
        navigation={false}
        className="mySwiper"
        autoplay={{
          delay: 1500,
          disableOnInteraction: false,
          waitForTransition: true,
        }}
      >
        {HOME_HERO_SLIDES.map((slide) => (
          <SwiperSlide key={slide.id}>
            <HeroSlide
              id={slide.id}
              imgSrc={slide.img}
              title={t(slide.title)}
              gradientColors={slide.gradientColors}
              description={t(slide.description)}
              handleOnClick={handleOnClick}
              productType={slide.productType}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default Hero;
