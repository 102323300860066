import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserGeneratedId, setUserGeoLocation } from "redux/slices/appStore";

import { generateUID } from "utils/global";
import { getGeolocation } from "utils/api/services/geolocation";
import { STORE_NAMES } from "utils/constants/redux";

const useGeoLocation = () => {
  const reduxStoreApp = useSelector((state) => state[STORE_NAMES.APP]);
  const dispatch = useDispatch();

  useEffect(() => {
    getGeolocation().then((data) => {
      dispatch(setUserGeoLocation(data));
    });
    if (!reduxStoreApp.userGeneratedId) {
      dispatch(setUserGeneratedId(generateUID()));
    }
  }, []);
};

export default useGeoLocation;
