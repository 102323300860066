const TRANSLATION = {
  buttons: {
    learnMore: "learn more",
    addToCart: "add to cart",
    price: "price",
    goToShopping: "Go To Shopping",
    shopNow: "Shop now",
    more: "Let’s see more",
    continue: "Continue",
    returnBack: "Return to back",
    apply: "Apply",
    shareCart: "Share your cart",
    tryAgain: "Try again",
    accept: "Accept",
    copied: "Link Copied",
  },
  products: {
    energy: { title: "Energy" },
    hairNail: { title: "Healthy Hair & Nails" },
    sleep: { title: "Good Sleep" },
    kids: { title: "Multivitamin for Kids" },
    energyCombo: { title: "Energy Combo" },
    hairNailCombo: { title: "Healthy Hair & Nails Combo" },
    sleepCombo: { title: "Good Sleep Combo" },
    kidsCombo: { title: "Multivitamin for Kids Combo" },
  },
  pages: {
    home: {
      name: "home",
      aboutUs: {
        title: "about us",
      },
      productInfo: {
        energy: {
          description:
            "Whether you're facing a challenging day at work, a demanding workout, or need a mental boost, Energy Gummies are the perfect solution",
        },
        hairNail: {
          description:
            "Convenient and enjoyable to consume, Healthy Hair & Nails Gummies are a hassle-free addition to your daily beauty routine",
        },
        sleep: {
          description:
            "Whether you struggle with occasional sleeplessness, jet lag, or simply want to establish a consistent sleep routine, Good Sleep Gummies provide an enjoyable solution",
        },
        kids: {
          description:
            "Multivitamin for kids Gummies are here to provide the essential nutrients necessary for their healthy growth and development",
        },
      },
      reviews: {
        title: "reviews",
      },
      riskFree: {
        title: "try it, risk free!",
        description:
          "If you’re not satisfied with our product, simply contact us and we’ll give you a full, 100% hassle-free refund.",
      },
      faq: {
        title: "FAQ",
        aboutProduct: {
          title: "About product",
          faqs: {
            faq1: {
              question: "Are there side effects?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            },
            faq2: {
              question: "Can supplements interact with medications?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            },
            faq3: {
              question: "Can supplements be harmful to children?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            },
            faq4: {
              question: "Are there side effects?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            },
            faq5: {
              question: "Can supplements interact with medications?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            },
          },
        },
        deliveryAndShipping: {
          title: "Delivery & Shipping Methods",
          faqs: {
            faq1: {
              question: "What are the different shipping options available?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ",
            },
            faq2: {
              question: "How long does shipping usually take?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ",
            },
            faq3: {
              question: "What are the shipping fees?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ",
            },
            faq4: {
              question: "How long does shipping usually take?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ",
            },
            faq5: {
              question: "What are the shipping fees?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ",
            },
          },
        },
      },
      hero: {
        free: "It’s free",
      },
    },
    shop: {
      name: "shop",
      productInfo: {
        containsLabel: "Each Tablets Contains",
        otherIngredientsLabel: "Other Ingredients",
        benefitsOf: "Benefits of ",
        ourProducts: "our products",
        GiaSleep: {
          name: "Good Sleep",
          subTitle:
            "Keep calm and sleep. They also control the formation of melatonin, a sleep hormone. ",
          description: {
            part1:
              'GIA\'s specially formulated "Good Sleep" Gummies are designed to help you relax and achieve a deep, rejuvenating slumber. These delicious gummies offer a natural and convenient way to promote relaxation and enhance your sleep quality.\n' +
              "Each gummy is infused with melatonin to help you fall asleep faster, stay asleep longer, and wake up feeling refreshed and revitalized.",
            part2:
              "Our gummy vitamins for sleep are a fantastic alternative to traditional sleep aids, as they are non-habit forming and gentle on your system. Plus, they're also delightful to consume, thanks to their irresistible\n" +
              "blueberry flavor and chewy texture.\n" +
              "Whether you struggle with occasional sleeplessness, jet lag, or simply want to establish a consistent sleep routine, Good Sleep Gummies provide an enjoyable solution. With their precise dosage and easy-to-digest form, you can take them before bedtime to drift off into a peaceful sleep.",
          },
          benefits: {
            benefit1: {
              title: "Fall Asleep Faster",
            },
            benefit2: {
              title: "Improve sleep quality",
            },
            benefit3: {
              title: "Wake up refreshed",
            },
          },
          otherIngredients: "BLUEBERRY FLAVOURING, MELATONIN",
          additionalInfo: {
            suggestedUse: "Suggested use: Take 2 gummies per day.",
            storage:
              "Storage at room temperature out of the reach of small children. Protect from sunlight. Store in a dry place.",
            riskAndSideEffects:
              "Do not exceed the recommended daily dose. Do not use it if you are allergic to any of the ingredients. The product should not be used by pregnant women, nursing mothers, and children. Food supplements should not be used as a substitute (replacement) for a varied diet.",
          },
          herbalBlendDetails: {
            tart: "Tart Cherry Powder (Prunus cerasus) (Fruit)",
            chamomile: "Chamomile Extract (Matricaria chamomilla) (Flower)",
            passion: "Passion Flower Extract (Pasiflora caerulea) (Flower)",
          },
        },
        GiaHairNail: {
          name: "Healthy Hair & Nails",
          subTitle:
            "Experience the transformation as our vitamins promote healthier, more resilient hair and nails.",
          description: {
            part1:
              'GIA\'s specially formulated "Healthy Hair & Nails" Gummies are designed to promote the health and beauty of your nails and hair. These delicious gummies are formulated with a blend of ingredients that nourish from within, helping you achieve stronger nails and luscious, vibrant hair.\n' +
              "Each gummy is packed with vitamins and key ingredients such as biotin, and sink to support the growth and vitality of your nails and hair. These nutrients work synergistically to fortify your nails, reduce brittleness, and enhance their natural beauty.",
            part2:
              "Our gummy vitamins for hair & nails not only provide healthy hair and nails, but they're also delicious to consume. A sweet raspberry flavor and chewy texture evoke sensations of fresh juicy berry fruit.\n" +
              "Convenient and enjoyable to consume, Healthy Hair & Nails Gummies are a hassle-free addition to your daily beauty routine. Nourish your body from the inside out, supporting the health and appearance of your nails and hair.",
          },
          benefits: {
            benefit1: {
              title: "Grow smooth healthy nails",
            },
            benefit2: {
              title: "Get strong shiny hair",
            },
            benefit3: {
              title: "Beauty Within",
            },
          },

          otherIngredients: "RASPBERRY FLAVORING, VITAMINS, BIOTIN, SINK",
          additionalInfo: {
            suggestedUse: "Suggested use: Take 2 gummies per day.",
            storage:
              "Storage at room temperature out of the reach of small children. Protect from sunlight. Store in a dry place.",
            riskAndSideEffects:
              "Do not exceed the recommended daily dose. Do not use it if you are allergic to any of the ingredients. The product should not be used by pregnant women, nursing mothers, and children. Food supplements should not be used as a substitute (replacement) for a varied diet.",
          },
          herbalBlendDetails: {
            tart: "Tart Cherry Powder (Prunus cerasus) (Fruit)",
            chamomile: "Chamomile Extract (Matricaria chamomilla) (Flower)",
            passion: "Passion Flower Extract (Pasiflora caerulea) (Flower)",
          },
        },
        GiaKids: {
          name: "Multivitamin for Kids",
          subTitle:
            "Yummy Gummies for kids.They also control the formation of melatonin, a sleep hormone.  ",
          description: {
            part1:
              "GIA's specially formulated “Multivitamin gummies for kids” are designed to nurture the health and happiness of growing children. These delightful gummies are formulated with a blend of essential vitamins and nutrients to provide the nutritional support your child needs for optimal growth and development.\n" +
              "Each gummy is packed with a rainbow of vitamins A, C, D, E, and B-complex. These nutrients work\n" +
              "together to support strong bones and teeth, boost immunity, aid cognitive function, and promote overall vitality.",
            part2:
              "Our multivitamin gummies for kids are not only nutritionally complete but also irresistibly delicious. Made with natural fruit flavors, these chewy gummies make taking daily vitamins a fun and enjoyable experience for children.\n" +
              "We understand that parents want the best for their children, and Multivitamin for kids Gummies are here to provide the essential nutrients necessary for their healthy growth and development. Say goodbye to the struggle of getting kids to take their vitamins and hello to a nutritious and tasty treat, they'll look forward to.",
          },
          benefits: {
            benefit1: {
              title: "Support Growth",
            },
            benefit2: {
              title: "Boost immunity",
            },
            benefit3: {
              title: "Provide a well-rounded nutritional foundation",
            },
          },
          otherIngredients:
            "FRUIT JUICE, EDIBLE ESSENCE, VITAMINS A, D, E, C, B6, B12, FOLIC ACID, NIACIN, BIOTIN",
          additionalInfo: {
            suggestedUse: "Suggested use: Take 2 gummies per day.",
            storage:
              "Storage at room temperature out of the reach of small children. Protect from sunlight. Store in a dry place.",
            riskAndSideEffects:
              "Do not exceed the recommended daily dose. Do not use it if you are allergic to any of the ingredients.Food supplements should not be used as a substitute (replacement) for a varied diet.",
          },
          herbalBlendDetails: {
            tart: "Tart Cherry Powder (Prunus cerasus) (Fruit)",
            chamomile: "Chamomile Extract (Matricaria chamomilla) (Flower)",
            passion: "Passion Flower Extract (Pasiflora caerulea) (Flower)",
          },
        },
        GiaEnergy: {
          name: "Energy",
          subTitle:
            "Feel Alive and Vibrant. Boost your energy levels and combat fatigue with energy vitamins.",
          description: {
            part1:
              'GIA\'s specially formulated "Energy" Gummies are designed to provide a natural and revitalizing source of energy. These delicious gummies are packed with energizing ingredients, offering a convenient and enjoyable way to stay alert, focused, and ready to take on the day.\n' +
              "Each gummy is infused with a blend of natural extracts that work synergistically to boost energy levels and promote mental clarity.",
            part2:
              "Our energy gummy vitamins support both physical and mental stamina, being ideal for busy professionals, students, athletes, or anyone in need of a reliable pick-me-up. Natural strawberry flavor and chewy texture offer a healthier and delicious approach to energy enhancement.\n" +
              "Whether you're facing a challenging day at work, a demanding workout, or need a mental boost, Energy Gummies are the perfect solution. With their precise dosage and ingredients, you can trust that you're\n" +
              "giving your body the support it needs to thrive without compromising your well-being.",
          },
          benefits: {
            benefit1: {
              title: "Boost natural energy",
            },
            benefit2: {
              title: "Gain mental clarity",
            },
            benefit3: {
              title: "Reduce fatigue",
            },
          },
          otherIngredients: "STRAWBERRY FLAVORING, CAFFEINE",
          additionalInfo: {
            suggestedUse: "Suggested use: Take 2 gummies per day.",
            storage:
              "Storage at room temperature out of the reach of small children. Protect from sunlight. Store in a dry place.",
            riskAndSideEffects:
              "Do not exceed the recommended daily dose. Do not use it if you are allergic to any of the ingredients. The product should not be used by pregnant women, nursing mothers, and children. Food supplements should not be used as a substitute (replacement) for a varied diet.",
          },
          herbalBlendDetails: {
            tart: "Tart Cherry Powder (Prunus cerasus) (Fruit)",
            chamomile: "Chamomile Extract (Matricaria chamomilla) (Flower)",
            passion: "Passion Flower Extract (Pasiflora caerulea) (Flower)",
          },
        },
        ingredients: {
          calories: "Calories",
          totalCarbohydrate: "Total Carbohydrate",
          totalSugars: "Total Sugars",
          vitaminD: "Vitamin D",
          magnesium: "Magnesium",
          sodium: "Sodium",
          lemonBalmExtract: "Lemon Balm Extract",
          herbalBlend: "Herbal Blend",
          melatonin: "Melatonin",
        },
      },
    },
    about: {
      name: "about",
    },
    info: {
      name: "info",
    },
    notFound: {
      name: "not found",
    },
    checkout: {
      information: "Information",
      shipping: "Shipping",
      payment: "Payment",
      about: "About",
      method: "Method",
      shipTo: "Ship to",
      contact: "Contact",
      shippingMethod: "Shipping method",
      shippingContact: "Shipping contact information",
      shippingAddress: "Shipping address",
      details: "Details",
      detailsSubtext: "Details of the",
      paymentSubtext: "All transfer are secured and encrypted",
      guaranteeInfo:
        "We offer a 30-day money back guarantee. If for any reason, you don't absolutely love GIA, we'll refund your money even if you send us back an opened box.",
      free: "Free",
      shippingTime: "About 5 day",
      pickupTime: "Available every day",
      pickupLocations: "Pick up locations",
      atBank: "Processing at bank",
      atAddress: "Processing at address",
      paymentErrorInfo: "We are not able to process your payment.",
      wentWrong: "Something went wrong",
      ohNo: "Oh no!",
      tryAgain: "Please try again",
      paymentSuccess: "Payment successful",
      received: "We’ve received your",
      paymentDetails: "Payment details",
      paymentDate: "Payment date",
      paidBy: "Paid by card/account",
      paymentAmount: "Payment amount",
      inputs: {
        name: "First name",
        lastName: "Last name",
        email: "E-Mail address",
        phone: "Phone",
        city: "City",
        addressLine1: "Address",
        addressLine2: "Apartment, suite, etc. (optional)",
        invalidEmailText: "Invalid email",
        invalidPhoneText: "Invalid phone number",
        promoCode: "Gift card or discount code",
        invalidPromoCode: "Invalid gift card or discount code",
        delivery: "Delivery at Address",
        pickup: "Pick Up",
        card: "Credit Card",
        cash: "Cash Payment",
      },
    },
  },
  components: {
    specialOffers: {
      title: "special offers",
      combo: "Combo",
    },
    basket: {
      cart: "Cart",
      total: "total",
      subtotal: "subtotal",
      promoDiscount: "Promo Discount",
      discount: "discount",
      emptyCart: "Your cart is empty",
      emptyCartContent:
        "Looks like you haven’t added anything to your cart yet",
      showOrderSummary: "Show order summary",
      hideOrderSummary: "Hide order summary",
    },
    ingredientLabels: {
      label1: {
        name: "Vegan",
        description: "Aside from excluding animal-derived ingredients ",
      },
      label2: {
        name: "No GMO",
        description: "Aside from excluding animal-derived ingredients ",
      },
      label3: {
        name: "Gelatine Free",
        description: "Aside from excluding animal-derived ingredients ",
      },
      label4: {
        name: "Bio-Based",
        description: "Aside from excluding animal-derived ingredients ",
      },
    },
    comingSoon: {
      title: "Coming soon",
    },
    shippingPaymentMethods: {
      name: "Shipping & Delivery",
      description:
        "Delivery will be made to the address you provide as soon as the goods have been paid for in full.",
      delivery: {
        title: "delivery",
        info: "The cargo is delivering address. ",
      },
      pickup: {
        title: "pick up",
        info: "Pick up from our address.",
      },
      card: {
        title: "card",
        info: "Pay with card during shopping.",
      },
      cash: {
        title: "cash",
        info: "Pay with cash.",
      },
    },
    footer: {
      quickActions: "Quick Actions",
      contacts: "Contacts",
      phone: "Phone",
      email: "Email",
      links: "Links",
      street: "Street",
      zip: "ZIP",
      city: "City",
      addresses: {
        title: "Address",
        address1: {
          title: "Time Business Center",
          addressLine:
            "(Time Tower) 16e Keykab Khanim Safaraliyeva St, Baku, Azerbaijan",
        },
      },
      pickupLocations: {
        title: "Pickup Locations",
        location1: {
          title: "Ganjlik Mall",
          street: "16e Keykab Khanim Safaraliyeva St",
          zip: "AZ1141",
          city: "Baku",
        },
        location2: {
          title: "28 May",
          street: "16e Keykab Khanim Safaraliyeva St",
          zip: "AZ1141",
          city: "Baku",
        },
        location3: {
          title: "Time Business Center",
          street: "16e Keykab Khanim Safaraliyeva St",
          zip: "AZ1141",
          city: "Baku",
        },
      },
      policyLinks: {
        title: "Legal",
        termsAndConditions: "Terms & Conditions",
        privacyPolicy: "Privacy Policy",
        returnPolicy: "Return Policy",
      },
      copyRights: "GIA Vitamins. All Rights Reserved.",
      madeBy: "Made by",
    },
    customSelect: {
      lang: "Language",
      curr: "Currency",
      language: {
        en: "English",
        az: "Azerbaijan",
        ru: "Russian",
      },
      currency: {
        azn: "Azerbaijan Manat",
        usd: "United States Dollars",
        eur: "Euro",
      },
    },
    policies: {
      header:
        "These general terms and conditions apply to all orders in the online shop at our website and govern your access to the site and use of any products or services offered through the site. All offers, services and deliveries are made exclusively on the basis of these General Terms and Conditions in the version valid at the time the order is placed. By accessing or using our website, you agree to be bound by these Terms and Conditions and our Privacy Policy. If you do not agree to these terms, please do not use our website.",
      terms: {
        title: "Terms & Conditions",
        process: {
          title: "Ordering process and acceptance of terms.",
          content:
            'The desired goods can be placed in a virtual shopping cart. The contents of the shopping cart can be checked and changed at any time by clicking on the shopping cart symbol. After clicking on the "Checkout" button, an order form opens in which the order-related data such as delivery and billing address, payment method and shipping method are entered. The contents of the shopping cart can be checked again immediately before the order is sent by clicking on the "Order now" button, the customer submits to conclude a purchase. After receipt of the purchase offer, we will send you an automatically generated email confirming that we have received the order (confirmation of receipt). The purchase concluded when we confirm acceptance of the purchase offer in a further e-mail (order confirmation). The acceptance of an order is subject to the condition that the customer has filled in all the mandatory information in the order form completely and correctly and has already reached the age of 18. Orders are only possible in normal household quantities.',
        },
        prices: {
          title: "Prices and shipping costs",
          content:
            "All prices stated in the online shop are in AZN and include statutory VAT and other price components, but not the shipping costs, which may have to be paid additionally in accordance with delivery type . \n Shipping costs are charged for orders within the Republic of Azerbaijan.",
        },
        warranty: {
          title: "Warranty, Liability",
          content:
            'Our website is provided on an "as is" and "as available" basis. We make no  representations or warranties of any kind, express or implied, as to the operation of our website or the information, content, materials, or products included on our website. We will not be liable for any damages of any kind arising from the use of our website, including but not limited to direct, indirect, incidental, punitive, and consequential damages.',
        },
        modification: {
          title: "Modification",
          content:
            "We reserve the right to modify these Terms and Conditions at any time. Any changes will be effective immediately upon posting on our website",
        },
      },
      privacy: {
        title: "Privacy Policy",
        dataProtection: {
          title: "Customer Account and Data Protection",
          content:
            "All orders and the order data entered by the customer are stored in the personal customer account of the customer, provided the customer creates a customer account. The customer determines the login data himself/herself when he/she places his/her order for the first time. Using this login data, the customer can access his customer account at any time. We generally do not pass on the data that you store in your customer account to third parties; in particular, it is not passed on for advertising purposes. However, data may be passed on to third parties for purposes that are necessary for the execution of the contract (e.g. passing on to financial institutions) or that are permitted or prescribed by law (e.g. due to tax regulations). Otherwise, our data protection regulations apply, which you can access, download and print at www.gia-vitamins.com/policies/privacy-policy.",
        },
        governingLow: {
          title: "Governing Law and Jurisdiction.",
          content: "",
        },
      },
      return: {
        title: "Return Policy",
        userEligibility: {
          title: "User Eligibility",
          content:
            "You must be at least 18 years old or have the legal capacity to enter into a binding agreement in your jurisdiction to use our services or access our website. By using our services or accessing our website, you represent and warrant that you meet the eligibility requirements.",
        },
        serviceDescription: {
          title: "Service Description",
          content:
            "Our services may include but are not limited. We reserve the right to modify, suspend, or discontinue any part of our services at any time without prior notice.",
        },
      },
    },
  },
};

export default TRANSLATION;
