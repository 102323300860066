import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateShipping } from "redux/slices/checkoutStore";
import CustomInput from "components/custom-input/CustomInput";
import { inputs } from "utils/constants/input";
import { ReactComponent as IconArrowLeft } from "assets/icons/arrows/arrow-left-gray.svg";
import "./Shipping.scss";

const Shipping = ({ onNext, onGoBack }) => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const shippingData = useSelector((state) => state.checkout.shippingData);

  const handleInputChange = (e, field, inputValue = null) => {
    const value = inputValue === null ? e.target.value : inputValue;
    dispatch(updateShipping({ fieldName: field, value: value }));
  };

  useEffect(() => {
    if (
      Object.keys(errors).length > 0 &&
      !Object.values(errors).find((error) => error)
    ) {
      onNext();
    }
  }, [errors]);

  const handleContinue = () => {
    setIsFormSubmitted(true);
    setTimeout(() => {
      setIsFormSubmitted(false);
    }, 0);
  };
  return (
    <div className="CheckoutStep Shipping">
      <h3>
        {t("pages.checkout.shippingContact")}
        <span>*</span>
      </h3>
      <form className="ShippingContactForm">
        <CustomInput
          label={t(inputs.phone.label)}
          type={inputs.phone.type}
          id="phone"
          name="phone"
          helperText={t(inputs.phone.helperText)}
          value={shippingData.phone}
          onValueChange={(e, inputValue) =>
            handleInputChange(e, "phone", inputValue)
          }
          required
          isFormSubmitted={isFormSubmitted}
          setErrors={setErrors}
        />
        {shippingData.method === "delivery" && (
          <CustomInput
            label={t(inputs.email.label)}
            type={inputs.email.type}
            id="email"
            name="email"
            value={shippingData.email}
            helperText={t(inputs.email.helperText)}
            onValueChange={(e) => handleInputChange(e, "email")}
            isFormSubmitted={isFormSubmitted}
            setErrors={setErrors}
          />
        )}
      </form>
      {shippingData.method === "delivery" ? (
        <div>
          <h3>{t("pages.checkout.shippingAddress")}</h3>
          <form className="AddressForm">
            <CustomInput
              label={t(inputs.city.label)}
              type={inputs.city.type}
              id="city"
              name="city"
              value={shippingData.city}
              onValueChange={(e) => handleInputChange(e, "city")}
              required
              isFormSubmitted={isFormSubmitted}
              setErrors={setErrors}
            />
            <div>
              <CustomInput
                label={t(inputs.name.label)}
                type={inputs.name.type}
                id="name"
                name="name"
                value={shippingData.name}
                onValueChange={(e) => handleInputChange(e, "name")}
                required
                isFormSubmitted={isFormSubmitted}
                setErrors={setErrors}
              />
              <CustomInput
                label={t(inputs.lastName.label)}
                type={inputs.lastName.type}
                id="lastName"
                name="lastName"
                value={shippingData.lastName}
                onValueChange={(e) => handleInputChange(e, "lastName")}
                required
                isFormSubmitted={isFormSubmitted}
                setErrors={setErrors}
              />
            </div>

            <CustomInput
              label={t(inputs.addressLine1.label)}
              type={inputs.addressLine1.type}
              id="addressLine1"
              name="addressLine1"
              value={shippingData.addressLine1}
              onValueChange={(e) => handleInputChange(e, "addressLine1")}
              required
              isFormSubmitted={isFormSubmitted}
              setErrors={setErrors}
            />
            <CustomInput
              label={t(inputs.addressLine2.label)}
              type={inputs.addressLine2.type}
              id="addressLine2"
              name="addressLine2"
              value={shippingData.addressLine2}
              onValueChange={(e) => handleInputChange(e, "addressLine2")}
              isFormSubmitted={isFormSubmitted}
              setErrors={setErrors}
            />
          </form>
        </div>
      ) : (
        <div>
          <h3>{t("pages.checkout.pickupLocations")}</h3>
          <div className="LocationsTable">
            <div className="Location">
              <div className="LocationInfo">
                <span>
                  {t("components.footer.pickupLocations.location1.title")}
                  {" - "}
                </span>
                <a
                  href="https://goo.gl/maps/muE9b7ei8DYwAbiq6?coh=178571&entry=tt"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("components.footer.pickupLocations.location1.street")}
                </a>
              </div>
              <div className="PickupHours">09:30 - 18:00</div>
            </div>
            <div className="Location">
              <div className="LocationInfo">
                <span>
                  {t("components.footer.pickupLocations.location2.title")}
                  {" - "}
                </span>
                <a
                  href="https://goo.gl/maps/muE9b7ei8DYwAbiq6?coh=178571&entry=tt"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("components.footer.pickupLocations.location2.street")}
                </a>
              </div>
              <div className="PickupHours">09:30 - 18:00</div>
            </div>
            <div className="Location">
              <div className="LocationInfo">
                <span>
                  {t("components.footer.pickupLocations.location3.title")}
                  {" - "}
                </span>
                <a
                  href="https://goo.gl/maps/muE9b7ei8DYwAbiq6?coh=178571&entry=tt"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("components.footer.pickupLocations.location3.street")}
                </a>
              </div>
              <div className="PickupHours">09:30 - 18:00</div>
            </div>
          </div>
        </div>
      )}
      <div className="CheckoutButtons">
        <button className="ReturnButton" onClick={onGoBack}>
          <IconArrowLeft /> {t("buttons.returnBack")}
        </button>
        <button className="ContinueButton" onClick={handleContinue}>
          {t("buttons.continue")}
        </button>
      </div>
    </div>
  );
};

export default Shipping;

Shipping.propTypes = {
  onNext: PropTypes.func.isRequired,
  onGoBack: PropTypes.func,
};
