import { useEffect, useState } from "react";
import debounce from "lodash/debounce";
import defaults from "lodash/defaults";
import {getDesktopBreakpoint, getTabletBreakpoint, getMobileBreakpoint} from "../global";

export const BREAKPOINT_NAMES = {
  mobile: "mobile",
  tablet: "tablet",
  desktop: "desktop",
};

export const BREAKPOINTS = {
  [BREAKPOINT_NAMES.mobile]: getMobileBreakpoint(),
  [BREAKPOINT_NAMES.tablet]: getTabletBreakpoint(),
  [BREAKPOINT_NAMES.desktop]: getDesktopBreakpoint(),
};

const defaultOptions = {
  breakpoints: BREAKPOINTS,
  delay: 0,
};

const useWindowSize = (options = {}) => {
  const { breakpoints, delay } = defaults(options, defaultOptions);

  const getSize = () => {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  };

  const [windowSize, setWindowSize] = useState(getSize);

  const width = windowSize.width;
  const height = windowSize.height;

  useEffect(() => {
    const _handleResize = () => {
      setWindowSize(getSize());
    };
    const handleResize = delay ? debounce(_handleResize, delay) : _handleResize;

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // This mimics the same behavior as the respond mixin in _breakpoints.scss
  const respond = (max) => {
    const maxSize = breakpoints[max];
    if (!maxSize) {
      console.error(
        `Breakpoint ${max} must be one of ${Object.keys(breakpoints).join(",")}`
      );
    } else {
      return width <= maxSize;
    }
  };

  return {
    isScreenSize: respond,
    size: {
      width,
      height,
    },
  };
};

export default useWindowSize;
