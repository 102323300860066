import { AXIOS_NO_AUTH } from "utils/api/axios";
import { ENDPOINTS } from "utils/constants/api";
import AXIOS from "../axios";

export const getHelloWorldAsync = async () => {
  return await AXIOS_NO_AUTH.get(ENDPOINTS.hello);
};

export const createOrder = (orderData) => {
  return AXIOS.post(`${ENDPOINTS.order}`, orderData);
};
