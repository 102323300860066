import React, { useEffect } from "react";
import HomeProductInfo from "pages/home/components/home-product-info/HomeProductInfo";
import BuyProductCarousel from "pages/home/components/buy-product-carousel/BuyProductCarousel";
import Hero from "pages/home/components/hero/Hero";
import RiskFree from "components/risk-free/RiskFree";
import IngredientLabels from "components/ingredient-labels/IngredientLabels";
import SpecialOffers from "components/special-offers/SpecialOffers";
import FaqList from "pages/home/components/faq-list/FaqList";
import AboutUs from "pages/home/components/about-us/AboutUs";
import Reviews from "components/reviews/Reviews";
import ShippingPaymentMethods from "components/shipping-payment-methods/ShippingPaymentMethods";
import "./Home.scss";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Hero />
      <IngredientLabels />
      <section className="SectionContainer">
        <BuyProductCarousel />
      </section>
      <RiskFree />
      <HomeProductInfo />
      <SpecialOffers />
      <AboutUs />
      <section className="SectionContainer">
        <FaqList />
      </section>
      <ShippingPaymentMethods />
      <Reviews />
    </>
  );
};

export default Home;
