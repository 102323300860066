import React from "react";
import { Link } from "react-router-dom";
import "./NotFound.scss";

const NotFound = () => {
  return (
    <div className="NotFoundPageContainer">
      <h1>Oops!</h1>
      <h5>404 - PAGE NOT FOUND</h5>
      <p>
        The page you are looking for might have been removed had its name
        changed or is temporarily unavailable
      </p>
      <Link to="/" className="goToHomePage">
        Go to homepage
      </Link>
    </div>
  );
};

export default NotFound;
