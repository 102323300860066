import { createSlice } from "@reduxjs/toolkit";
import { STORE_NAMES } from "utils/constants/redux";

const INITIAL_STATE = {
  clientData: {
    name: "",
    lastName: "",
    phone: "",
    email: "",
  },
  shippingData: {
    method: "delivery",
    name: "",
    lastName: "",
    phone: "",
    email: "",
    city: "Baku",
    addressLine1: "",
    addressLine2: "",
  },
  payment: {
    method: "card",
  },
};

const checkoutSlice = createSlice({
  name: STORE_NAMES.CHECKOUT,
  initialState: INITIAL_STATE,
  reducers: {
    updateClientData: (state, action) => {
      const { fieldName, value } = action.payload;

      return {
        ...state,
        clientData: {
          ...state.clientData,
          [fieldName]: value,
        },
      };
    },
    updateShipping: (state, action) => {
      const { fieldName, value } = action.payload;

      return {
        ...state,
        shippingData: {
          ...state.shippingData,
          [fieldName]: value,
        },
      };
    },
    updatePayment: (state, action) => {
      const method = action.payload;
      return {
        ...state,
        payment: {
          ...state.payment,
          method: method,
        },
      };
    },
    resetCheckoutStore: () => {
      return INITIAL_STATE;
    },
  },
});

export const {
  updateClientData,
  updateShipping,
  updatePayment,
  resetCheckoutStore,
} = checkoutSlice.actions;

export default checkoutSlice.reducer;
