import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addProduct,
  decreaseProductCount,
  removeProduct,
} from "redux/slices/basketStore";
import CartItem from "components/cart/sub-components/cart-item/CartItem";
import "./Cart.scss";

const Cart = () => {
  const basketItems = useSelector((state) => state.basket.products);
  const dispatch = useDispatch();

  const handleAdd = (product) => {
    dispatch(addProduct(product));
  };

  const handleDecrease = (itemId) => {
    dispatch(decreaseProductCount(itemId));
  };

  const handleDeleteItem = (itemId) => {
    dispatch(removeProduct(itemId));
  };

  return (
    <div className="CartItemsWrapper">
      {basketItems.map((item) => (
        <CartItem
          key={item.id}
          onDeleteItem={() => handleDeleteItem(item.id)}
          onAdd={() => handleAdd(item)}
          onRemove={() => handleDecrease(item.id)}
          imgSrc={item.img}
          count={item.count}
          price={item.price * item.count}
          title={item.title}
        />
      ))}
    </div>
  );
};

export default Cart;
