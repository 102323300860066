import React from "react";
import { ROUTE_NAME } from "utils/constants/routes";
import { useURLMatch } from "utils/hooks/useURLMatch";
import ICON_WHATSAPP from "assets/icons/social-media/icon-whatsapp-yellow.png";
import { ReactComponent as IconArrowUp } from "assets/icons/arrows/arrow-up.svg";
import "./QuickActionsBar.scss";

const QuickActionsBar = () => {
  const isURLMatch = useURLMatch(ROUTE_NAME.checkout);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  if (isURLMatch) {
    return;
  }

  return (
    <div className="QuickActionsBar">
      <button className="scrollToTop" onClick={handleScrollToTop}>
        <IconArrowUp />
      </button>
      <a
        href="https://api.whatsapp.com/send?phone=+994552090072"
        className="goToWhatsappChat"
        target="_blank"
        rel="noreferrer"
      >
        <img src={ICON_WHATSAPP} alt="whatsapp icon" />
      </a>
    </div>
  );
};

export default QuickActionsBar;
