import React from "react";
import { useTranslation } from "react-i18next";
import "./RiskFree.scss";

const RiskFree = () => {
  const { t } = useTranslation();
  return (
    <div className="RiskFree">
      <div className="SectionContainer">
        <h2>{t("pages.home.riskFree.title")}</h2>
        <p>{t("pages.home.riskFree.description")}</p>
      </div>
    </div>
  );
};

export default RiskFree;
